import Dexie, { Table } from 'dexie';
import { BehaviorSubject } from 'rxjs';

import {
  CachedData,
  IReservationCompany,
  IReservationCruiseLine,
  IReservationCustomRequestType,
  IReservationDestination,
  IReservationGeoTree,
  IReservationOccupation,
  IReservationPriceType,
  IReservationProductType,
  IReservationShip,
  IReservationSite,
  IReservationExperienceType,
  IReservationReceiptType,
  IReservationPhonePrefix,
  IReservationLanguage,
  IReservationSalutation,
  IReservationTransferType,
  IReservationPriceSetup,
  IReservationPkgItineraryType,
  IReservationPackageType,
  IReservationBrand,
  IReservationLoyaltyDiscount,
  IReservationCurrency,
  IReservationNoteType,
  IReservationSuiteCategory,
  IReservationSuiteStatus,
  IReservationSuiteCapacity,
  IReservationSuiteAmenity,
  IReservationLoyaltyRedemptionTier,
  IReservationCertificateType,
  IReservationManifestType,
  IReservationPackageStatus,
  IReservationPackageMeal,
  IReservationPackageCategory,
  IReservationPackagePhysicalLevel,
  IReservationPackageKidFriendly,
  IReservationPackageKidFriendlyTo,
  IReservationPackageMealType,
  IReservationCommissionPayoutBkgStatus,
  IReservationCommissionPayoutType,
  IReservationServiceType,
  IReservationCommissionPayoutBatchStatus,
  IReservationSearchableSuiteCategory,
  ITravelStudioSite,
  IResApiBookingCancelReason,
  IReservationApiGeotreeType,
  IReservationProductCategoryType,
  IReservationApiDepartment,
  IReservationApiBestTimeToGo,
  IReservationApiTimeZone,
  IReservationMovingType,
  IResPackageActivityType,
  IReservationPromoPackageProductType
} from '@reservation/models/res-cached-data.models';
import { IResSuiteDeckplanParams } from '@app/reservation/models/res-search.models';
import { IEidosCacheConfig } from '../config/environment.interface';
import { IPromoActionType, IPromoCombinability, IPromoStatus, IPromoType } from '@app/reservation/models/res-promo.model';

interface ICoreCacheTableTimestamp {
  TableName: CachedData;
  Timestamp?: number;
}

type CacheDB = {
  [key in CachedData]: {
    table: Table<any, number | string>;
    tableName: CachedData;
    /**
     * If a ordered by field is needed
     * then is mandatory to set the field
     * as index in the table store definition
     *
     * See this.version(version).stores in this file
     *
     * @type {string}
     */
    orderField?: string;
    serverMap: { db?: string, schema?: string, sp?: string };
  }
}

/**
 * Core cache DB based on Dexie library
 *
 * @export
 * @class CoreCacheDB
 * @extends {Dexie}
 */
export class CoreCacheDB extends Dexie {

  /**
   * Cache in IndexedDB
   *
   * @type {CacheDB}
   * @memberof CoreCacheDB
   */
  cache!: CacheDB;
  /**
   * Cache disabled flag
   *
   * @type {BehaviorSubject<boolean>}
   * @memberof CoreCacheDB
   */
  cacheDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /**
   * Cache ready flag
   *
   * @type {BehaviorSubject<boolean>}
   * @memberof CoreCacheDB
   */
  cacheReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /**
   * Current cache DB name
   *
   * @private
   * @type {string}
   * @memberof CoreCacheDB
   */
  private dbName: string = '';

  timestamps!: Table<ICoreCacheTableTimestamp, string>;
  /**
   * Table of all price types
   *
   * @type {Table<IReservationPriceType, number>}
   * @memberof CoreCacheDB
   */
  private allPriceTypes!: Table<IReservationPriceType, number>;
  /**
   * Table of all product types
   *
   * @private
   * @type {Table<IReservationProductType, string>}
   * @memberof CoreCacheDB
   */
  private allProductCodes!: Table<IReservationProductType, string>;
  /**
   * Table of all cities/geotrees
   *
   * @private
   * @type {Table<IReservationGeoTree, number>}
   * @memberof CoreCacheDB
   */
  private allCities!: Table<IReservationGeoTree, number>;
  /**
   * Table of all destinations
   *
   * @private
   * @type {Table<IReservationDestination, number>}
   * @memberof CoreCacheDB
   */
  private allDestinations!: Table<IReservationDestination, number>;
  /**
   * Table of all cruise lines
   *
   * @private
   * @type {Table<IReservationCruiseLine, number>}
   * @memberof CoreCacheDB
   */
  private allCruiseLines!: Table<IReservationCruiseLine, number>;
  /**
   * Table of all ships
   *
   * @private
   * @type {Table<IReservationShip, number>}
   * @memberof CoreCacheDB
   */
  private allShips!: Table<IReservationShip, number>;
  /**
   * Table of all custom request types
   *
   * @private
   * @type {Table<IReservationCustomRequestType, number>}
   * @memberof CoreCacheDB
   */
  private allCustomRequestTypes!: Table<IReservationCustomRequestType, number>;
  /**
   * Table of all sites
   *
   * @private
   * @type {Table<IReservationSite, number>}
   * @memberof CoreCacheDB
   */
  private allSites!: Table<IReservationSite, number>;
  /**
   * Table of all Travel Studio sites
   *
   * @private
   * @type {Table<ITravelStudioSite, number>}
   * @memberof CoreCacheDB
   */
  private allTSSites!: Table<ITravelStudioSite, number>;
  /**
   * Table of all Travel Studio sites
   *
   * @private
   * @type {Table<IResApiBookingCancelReason, number>}
   * @memberof CoreCacheDB
   */
  private allCancelReasons!: Table<IResApiBookingCancelReason, number>;

  private allGeoTreeTypes!: Table<IReservationApiGeotreeType, number>;
  private allDepartments!: Table<IReservationApiDepartment, number>;
  private allBestTimeToGo!: Table<IReservationApiBestTimeToGo, number>;
  private allTimeZone!: Table<IReservationApiTimeZone, number>;
  private allMovingTypes!: Table<IReservationMovingType, number>;

  /**
   * Table of all companies
   *
   * @private
   * @type {Table<IReservationCompany, number>}
   * @memberof CoreCacheDB
   */
  private allCompanies!: Table<IReservationCompany, number>;
  /**
   * Table of all experience types
   *
   * @private
   * @type {Table<IReservationExperienceType, number>}
   * @memberof CoreCacheDB
   */
  private allExperienceTypes!: Table<IReservationExperienceType, number>;
  /**
   * Table of all occupations
   *
   * @private
   * @type {Table<IReservationOccupation, number>}
   * @memberof CoreCacheDB
   */
  private allOccupations!: Table<IReservationOccupation, number>;
  /**
   * Table of all receipt types
   *
   * @private
   * @type {Table<IReservationReceiptType, number>}
   * @memberof CoreCacheDB
   */
  private allReceiptTypes!: Table<IReservationReceiptType, number>;
  /**
   * Table of all countries
   *
   * @private
   * @type {Table<IReservationGeoTree, number>}
   * @memberof CoreCacheDB
   */
  private allCountries!: Table<IReservationGeoTree, number>;
  /**
   * Table of all phone prefixes
   *
   * @private
   * @type {Table<IReservationPhonePrefix, number>}
   * @memberof CoreCacheDB
   */
  private allPhonePrefixes!: Table<IReservationPhonePrefix, number>;
  /**
   * Table of all languages
   *
   * @private
   * @type {Table<IReservationLanguage, number>}
   * @memberof CoreCacheDB
   */
  private allLanguages!: Table<IReservationLanguage, number>;
  /**
   * Table of all salutations
   *
   * @private
   * @type {Table<IReservationSalutation, number>}
   * @memberof CoreCacheDB
   */
  private allSalutations!: Table<IReservationSalutation, number>;
  /**
   * Table of all transfer types
   *
   * @private
   * @type {Table<IReservationTransferType, number>}
   * @memberof CoreCacheDB
   */
  private allTransferTypes!: Table<IReservationTransferType, number>;
  /**
  * Table of all price setups
  *
  * @private
  * @type {Table<IReservationPriceSetup, number>}
  * @memberof CoreCacheDB
  */
  private allPriceSetups!: Table<IReservationPriceSetup, number>;
  /**
   *
   *
   * @private
   * @type {Table<IReservationPkgItineraryType, number>}
   * @memberof CoreCacheDB
   */
  private allPkgItineraryTypes!: Table<IReservationPkgItineraryType, number>;
  /**
   *
   *
   * @private
   * @type {Table<IReservationPackageType, number>}
   * @memberof CoreCacheDB
   */
  private allPackageTypes!: Table<IReservationPackageType, number>;
  /**
   *
   *
   * @private
   * @type {Table<IReservationPackageType, number>}
   * @memberof CoreCacheDB
   */
  private allProductCategoryTypes!: Table<IReservationProductCategoryType, number>;
  /**
   *
   *
   * @private
   * @type {Table<IReservationPackageStatus, string>}
   * @memberof CoreCacheDB
   */
  private allPackageStatus!: Table<IReservationPackageStatus, string>;
  /**
   *
   *
   * @private
   * @type {Table<IReservationPackageMeal, string>}
   * @memberof CoreCacheDB
   */
  private allPackageMeals!: Table<IReservationPackageMeal, string>;
  /**
   *
   *
   * @private
   * @type {Table<IReservationPackageCategory, string>}
   * @memberof CoreCacheDB
   */
  private allPackageCategories!: Table<IReservationPackageCategory, string>;
  /**
   *
   *
   * @private
   * @type {Table<IReservationPackagePhysicalLevel, string>}
   * @memberof CoreCacheDB
   */
  private allPackagePhysicalLevels!: Table<IReservationPackagePhysicalLevel, string>;
  /**
   *
   *
   * @private
   * @type {Table<IReservationPackageKidFriendly, string>}
   * @memberof CoreCacheDB
   */
  private allPackageKidFriendlies!: Table<IReservationPackageKidFriendly, string>;
  /**
   *
   *
   * @private
   * @type {Table<IReservationPackageKidFriendlyTo, string>}
   * @memberof CoreCacheDB
   */
  private allPackageKidFriendliesTo!: Table<IReservationPackageKidFriendlyTo, string>;
  /**
   *
   *
   * @private
   * @type {Table<IReservationBrand, number>}
   * @memberof CoreCacheDB
   */
  private allBrands!: Table<IReservationBrand, number>;
  /**
   *
   *
   * @private
   * @type {Table<IReservationLoyaltyDiscount, number>}
   * @memberof CoreCacheDB
   */
  private allLoyaltyDiscounts!: Table<IReservationLoyaltyDiscount, number>;
  /**
   * All currencies
   *
   * @private
   * @type {Table<IReservationCurrency, number>}
   * @memberof CoreCacheDB
   */
  private allCurrencies!: Table<IReservationCurrency, string>;
  /**
   *
   *
   * @private
   * @type {Table<IReservationNoteType, number>}
   * @memberof CoreCacheDB
   */
  private allNoteTypes!: Table<IReservationNoteType, number>;
  /**
   *
   *
   * @private
   * @type {Table<IReservationSuiteCategory, number>}
   * @memberof CoreCacheDB
   */
  private allSuiteCategories!: Table<IReservationSuiteCategory, number>;

  /**
   *
   *
   * @private
   * @type {Table<IResSuiteDeckplanParams, number>}
   * @memberof CoreCacheDB
   */
  private allDeckplans!: Table<IResSuiteDeckplanParams, string>;
  /**
   * All suite status
   *
   * @private
   * @type {Table<IReservationSuiteStatus, string>}
   * @memberof CoreCacheDB
   */
  private allSuiteStatus!: Table<IReservationSuiteStatus, string>;
  /**
   * All suite capacities
   *
   * @private
   * @type {Table<IReservationSuiteCapacity, string>}
   * @memberof CoreCacheDB
   */
  private allSuiteCapacities!: Table<IReservationSuiteCapacity, string>;
  /**
   * All suite amenities
   *
   * @private
   * @type {Table<IReservationSuiteAmenity, string>}
   * @memberof CoreCacheDB
   */
  private allSuiteAmenities!: Table<IReservationSuiteAmenity, string>;

  /**
   * All Loyalty Redemptions
   *
   * @private
   * @type {Table<IReservationLoyaltyRedemptionTier, number>}
   * @memberof CoreCacheDB
   */
  private allLoyaltyRedemptionTiers!: Table<IReservationLoyaltyRedemptionTier, number>;

  /**
   * All certificate types
   *
   * @private
   * @type {Table<IReservationCertificateType, number>}
   * @memberof CoreCacheDB
   */
  private allCertificateTypes!: Table<IReservationCertificateType, number>;

  /**
   * All guest types
   *
   * @private
   * @type {Table<IReservationLoyaltyDiscount, number>}
   * @memberof CoreCacheDB
   */
  private allGuestTypes!: Table<IReservationLoyaltyDiscount, number>;

  /**
   * All manifest types
   *
   * @private
   * @type {Table<IReservationManifestType, number>}
   * @memberof CoreCacheDB
   */
  private allManifestTypes!: Table<IReservationManifestType, number>;

  /**
   * All package meal types
   *
   * @private
   * @type {Table<IReservationPackageMealType, number>}
   * @memberof CoreCacheDB
   */
  private allPackageMealTypes!: Table<IReservationPackageMealType, number>;

  /**
   * All commission payout types
   *
   * @private
   * @type {Table<IReservationCommissionPayoutType, string>}
   * @memberof CoreCacheDB
   */
  private allCommissionPayoutTypes!: Table<IReservationCommissionPayoutType, string>;

  /**
   * All commission payout bkg status
   *
   * @private
   * @type {Table<IReservationCommissionPayoutBkgStatus, string>}
   * @memberof CoreCacheDB
   */
  private allCommissionPayoutBkgStatus!: Table<IReservationCommissionPayoutBkgStatus, string>;
  /**
   * All service types
   *
   * @private
   * @type {Table<IReservationServiceType, number>}
   * @memberof CoreCacheDB
   */
  private allServiceTypes!: Table<IReservationServiceType, number>;
  /**
   * All service types
   *
   * @private
   * @type {Table<IReservationServiceType, number>}
   * @memberof CoreCacheDB
   */
  private allCommissionPayoutBatchStatus!: Table<IReservationCommissionPayoutBatchStatus, number>;

  /**
   * All promo combinability types
   *
   * @private
   * @type {Table<IPromoCombinability, number>}
   * @memberof CoreCacheDB
   */
  private allPromoCombinabilities!: Table<IPromoCombinability, number>

  private allPromoTypes!: Table<IPromoType, number>

  private allPromoActionTypes!: Table<IPromoActionType, number>

  private allPromoStatus!: Table<IPromoStatus, number>;
  /**
   * All Searchable Suite Categories
   *
   * @private
   * @type {Table<IReservationSearchableSuiteCategory, number>}
   * @memberof CoreCacheDB
   */
  private allSearchableSuiteCategories!: Table<IReservationSearchableSuiteCategory, number>;

  private allPackageActivityTypes!: Table<IResPackageActivityType, number>;

  private allPromoPackageProductTypes!: Table<IReservationPromoPackageProductType, number>;

  // Try to not add dependencies to avoid circular dependencies
  constructor({ version, enabled }: IEidosCacheConfig) {
    /**
     * Setup Dexie
     *
     * NB: remember to increment the value in this.version(n) below
     * on insert/edit/remove tables in this.cache
     */
    super(`ResDB-${window.location.host}`);
    this.dbName = `ResDB-${window.location.host}`;

    /**
     ****************************************************************
     ************************** WARNING *****************************
     *** Remember to increment the version value in eidos-config ****

     If you need to make table sorted by a field, it is mandatory to
     add the field as index to the table.

     ES: '++Cod, Position' means Cod is the primary key and an index
     will built for Position.

     ****************************************************************
     */

    /**
     * Define tables of this store version
     * including their primary key and indexes
     * https://dexie.org/docs/Version/Version.stores()
     */
    this.version(version).stores({
      timestamps: '++TableName',
      allPriceTypes: '++PriceTypeID',
      allProductCodes: '++ProductCod',
      allCities: '++CityID',
      allDestinations: '++DestinationID',
      allShips: '[ShipID+CompanyID]',
      allCruiseLines: '++CruiseLineID',
      allCustomRequestTypes: '++TypeID',
      allSites: '++SiteID',
      allCompanies: '++CompanyID',
      allExperienceTypes: '++TagID',
      allOccupations: '++Occupation',
      allReceiptTypes: '++ReceiptTypeID',
      allCountries: '++CountryID',
      allPhonePrefixes: '++Cod',
      allLanguages: '++LanguageId',
      allSalutations: '++SalutationId',
      allTransferTypes: '++TransferTypeID',
      allPriceSetups: '++PriceSetUpID',
      allPkgItineraryTypes: '++ItineraryTypeID',
      allPackageTypes: '++PackageTypeID',
      allProductCategoryTypes: '++ProductTypeID',
      allBrands: '++BrandID',
      allLoyaltyDiscounts: '++LoyaltyDiscountID',
      allCurrencies: '++CurrencyCod',
      allNoteTypes: '++NoteTypeID',
      allSuiteCategories: '++SuiteCategoryID',
      allDeckplans: '[ShipCode+EntryDate+DeckPlanStartFrom]',
      allSuiteStatus: '++SuiteStatusCod',
      allSuiteCapacities: '++SuiteCapacityID',
      allSuiteAmenities: '++AmenityID',
      allLoyaltyRedemptionTiers: '++LoyaltyRedemptionID',
      allCertificateTypes: '++CertificateTypeID',
      allGuestTypes: '++GuestTypeID',
      allManifestTypes: '++ManifestType',
      allPackageStatus: '++Cod, Position',
      allPackageMeals: '++Cod, Position',
      allPackageMealTypes: '++MealID',
      allPackageCategories: '++Cod, Position',
      allPackagePhysicalLevels: '++Cod, Position',
      allPackageKidFriendlies: '++Cod, Position',
      allPackageKidFriendliesTo: '++Cod, Position',
      allCommissionPayoutTypes: '++CommissionTypeCod',
      allCommissionPayoutBkgStatus: '++BkgStatus',
      allServiceTypes: '++ServiceTypeID',
      allPromoCombinabilities: '++PromoCombinabilityID',
      allPromoTypes: '++PromoTypeID',
      allPromoActionTypes: '++PromoActionTypeID',
      allPromoStatus: '++PromoStatusID',
      allCommissionPayoutBatchStatus: '++BatchStatusID',
      allSearchableSuiteCategories: '[Value+CompanyID]',
      allTSSites: '++SiteID',
      allCancelReasons: '++CancelReasonID',
      allGeoTreeTypes: '++GeoTreeTypeID',
      allDepartments: '++DepartmentID',
      allBestTimeToGo: '++BestTimeToGoID',
      allTimeZone: '++TimeZoneID',
      allMovingTypes: '++MovingTypeID',
      allPackageActivityTypes: '++ActivityPackageTypeID',
      allPromoPackageProductTypes: '++PromoProductTypeID',
    });

    /**
     * Initialize the cache and the server map
     *
     * NB: remember to increment the version value in eidos-config
     * on insert/edit/remove tables in this.cache
     */
    this.cache = {
      PriceTypes: {
        table: this.allPriceTypes,
        tableName: 'PriceTypes',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'PriceType_Get' },
      },
      ProductTypes: {
        table: this.allProductCodes,
        tableName: 'ProductTypes',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'ProductCod_Select' },
      },
      GeoTree: {
        table: this.allCities,
        tableName: 'GeoTree',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'GeoTree_Select' },
      },
      Destinations: {
        table: this.allDestinations,
        tableName: 'Destinations',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'Destination_Select' },
      },
      CruiseLines: {
        table: this.allCruiseLines,
        tableName: 'CruiseLines',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'CruiseLine_Select' },
      },
      Ships: {
        table: this.allShips,
        tableName: 'Ships',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'Ship_Select' },
      },
      CustomRequestTypes: {
        table: this.allCustomRequestTypes,
        tableName: 'CustomRequestTypes',
        serverMap: { db: 'RES', schema: 'Request', sp: 'RequestType_Select' },
      },
      Sites: {
        table: this.allSites,
        tableName: 'Sites',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'Site_Select' },
      },
      Companies: {
        table: this.allCompanies,
        tableName: 'Companies',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'Company_Select' },
      },
      ExperienceTypes: {
        table: this.allExperienceTypes,
        tableName: 'ExperienceTypes',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'ExperienceType_Select' },
      },
      Occupations: {
        table: this.allOccupations,
        tableName: 'Occupations',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'Occupation_Select' },
      },
      ReceiptTypes: {
        table: this.allReceiptTypes,
        tableName: 'ReceiptTypes',
        serverMap: { db: 'RES', schema: 'Bkg', sp: 'ReceiptType_Select' },
      },
      Countries: {
        table: this.allCountries,
        tableName: 'Countries',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'Country_Select' },
      },
      PhonePrefixes: {
        table: this.allPhonePrefixes,
        tableName: 'PhonePrefixes',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'PhonePrefix_Select' },
      },
      Languages: {
        table: this.allLanguages,
        tableName: 'Languages',
        serverMap: { db: 'RES', schema: 'Setup', sp: 'sp_Language_Select' },
      },
      Salutations: {
        table: this.allSalutations,
        tableName: 'Salutations',
        serverMap: { db: 'RES', schema: 'CM', sp: 'sp_Salutation_Select' },
      },
      TransferTypes: {
        table: this.allTransferTypes,
        tableName: 'TransferTypes',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'TransferType_Select' },
      },
      PriceSetups: {
        table: this.allPriceSetups,
        tableName: 'PriceSetups',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'PriceSetup_Select' },
      },
      PkgItineraryTypes: {
        table: this.allPkgItineraryTypes,
        tableName: 'PkgItineraryTypes',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'PackageItineraryType_Get' },
      },
      PackageTypes: {
        table: this.allPackageTypes,
        tableName: 'PackageTypes',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'PackageType_Get' },
      },
      ProductCategoryTypes: {
        table: this.allProductCategoryTypes,
        tableName: 'ProductCategoryTypes',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'ProductType_Get' },
      },
      PackageStatus: {
        table: this.allPackageStatus,
        tableName: 'PackageStatus',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'PackageStatus_Select' },
      },
      PackageMeals: {
        table: this.allPackageMeals,
        tableName: 'PackageMeals',
        orderField: 'Position',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'PackageMeal_Select' },
      },
      PackageMealTypes: {
        table: this.allPackageMealTypes,
        tableName: 'PackageMealTypes',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'PackageMealType_Select' },
      },
      PackageCategories: {
        table: this.allPackageCategories,
        tableName: 'PackageCategories',
        orderField: 'Position',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'PackageCategory_Select' },
      },
      PackagePhysicalLevels: {
        table: this.allPackagePhysicalLevels,
        tableName: 'PackagePhysicalLevels',
        orderField: 'Position',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'PackagePhysicalLevel_Select' },
      },
      PackageKidFriendlies: {
        table: this.allPackageKidFriendlies,
        tableName: 'PackageKidFriendlies',
        orderField: 'Position',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'PackageKidFriendly_Select' },
      },
      PackageKidFriendliesTo: {
        table: this.allPackageKidFriendliesTo,
        tableName: 'PackageKidFriendliesTo',
        orderField: 'Position',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'PackageKidFriendlyTo_Select' },
      },
      Brands: {
        table: this.allBrands,
        tableName: 'Brands',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'Brand_Get' },
      },
      LoyaltyDiscounts: {
        table: this.allLoyaltyDiscounts,
        tableName: 'LoyaltyDiscounts',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'Discount_Get' },
      },
      Currencies: {
        table: this.allCurrencies,
        tableName: 'Currencies',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'Currency_Get' },
      },
      NoteTypes: {
        table: this.allNoteTypes,
        tableName: 'NoteTypes',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'NoteType_Get' },
      },
      SuiteCategories: {
        table: this.allSuiteCategories,
        tableName: 'SuiteCategories',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'SuiteCategoryList_GET' },
      },
      Deckplans: {
        table: this.allDeckplans,
        tableName: 'Deckplans',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'SuiteCategoryList_GET' },
      },
      SuiteStatus: {
        table: this.allSuiteStatus,
        tableName: 'SuiteStatus',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'SuiteStatus_Get' },
      },
      SuiteCapacities: {
        table: this.allSuiteCapacities,
        tableName: 'SuiteCapacities',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'SuiteCapacity_Get' },
      },
      SuiteAmenities: {
        table: this.allSuiteAmenities,
        tableName: 'SuiteAmenities',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'Suite_Amenities' },
      },
      LoyaltyRedemptionTiers: {
        table: this.allLoyaltyRedemptionTiers,
        tableName: 'LoyaltyRedemptionTiers',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'RedemptionTier_Select' },
      },
      CertificateTypes: {
        table: this.allCertificateTypes,
        tableName: 'CertificateTypes',
        serverMap: { db: 'RES', schema: 'Bkg', sp: 'CertificateType_Get' },
      },
      GuestTypes: {
        table: this.allGuestTypes,
        tableName: 'GuestTypes',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'GuestType_Get' },
      },
      ManifestTypes: {
        table: this.allManifestTypes,
        tableName: 'ManifestTypes',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'ManifestType' },
      },
      CommissionPayoutTypes: {
        table: this.allCommissionPayoutTypes,
        tableName: 'CommissionPayoutTypes',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'CommissionPayout_CommissionType_Get' },
      },
      CommissionPayoutBookingStatus: {
        table: this.allCommissionPayoutBkgStatus,
        tableName: 'CommissionPayoutBookingStatus',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'CommissionPayout_BkgStatus_Get' },
      },
      ServiceTypes: {
        table: this.allServiceTypes,
        tableName: 'ServiceTypes',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'ServiceTypeSelect' },
      },
      PromoCombinabilities: {
        table: this.allPromoCombinabilities,
        tableName: 'PromoCombinabilities',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'PromoCombinability_Select' },
      },
      PromoTypes: {
        table: this.allPromoTypes,
        tableName: 'PromoTypes',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'PromoType_Select' },
      },
      PromoActionTypes: {
        table: this.allPromoActionTypes,
        tableName: 'PromoActionTypes',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'PromoActionType_Select' },
      },
      PromoStatus: {
        table: this.allPromoStatus,
        tableName: 'PromoStatus',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'PromoStatus_Select' },
      },
      CommissionPayoutBatchStatus: {
        table: this.allCommissionPayoutBatchStatus,
        tableName: 'CommissionPayoutBatchStatus',
        serverMap: { db: 'RES', schema: 'Bkg', sp: 'CommissionPayout_BatchStatus_Get' },
      },
      SearchableSuiteCategories: {
        table: this.allSearchableSuiteCategories,
        tableName: 'SearchableSuiteCategories',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'AvailableCategories_VoyageSearch' },
      },
      TSSites: {
        table: this.allTSSites,
        tableName: 'TSSites',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'AKDATA_Sites_Get' },
      },
      CancelReasons: {
        table: this.allCancelReasons,
        tableName: 'CancelReasons',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'CancelReason_Select' },
      },
      GeoTreeTypes: {
        table: this.allGeoTreeTypes,
        tableName: 'GeoTreeTypes',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'DestinationType_Select' },
      },
      Departments: {
        table: this.allDepartments,
        tableName: 'Departments',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'Department_get' },
      },
      BestTimeToGo: {
        table: this.allBestTimeToGo,
        tableName: 'BestTimeToGo',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'BestTimeToGo_Select' },
      },
      TimeZone: {
        table: this.allTimeZone,
        tableName: 'TimeZone',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'TimeZone_Select' },
      },
      MovingTypes: {
        table: this.allMovingTypes,
        tableName: 'MovingTypes',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'MovingType_Get' },
      },
      PackageActivityTypes: {
        table: this.allPackageActivityTypes,
        tableName: 'PackageActivityTypes',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'PackageActivityType_Select' },
      },
      PromoPackageProductTypes: {
        table: this.allPromoPackageProductTypes,
        tableName: 'PromoPackageProductTypes',
        serverMap: { db: 'RES', schema: 'dbo', sp: 'PromoProductType_Get' },
      },


  }

    const self = this;
    if (enabled) {
      const resDbVersion = localStorage.getItem(`${this.dbName}-version`);
      const mustRecreate = !!resDbVersion && !isNaN(+resDbVersion) && (+resDbVersion < (version ?? 0));

      this.open().then(async function () {

        mustRecreate && await self.resetDatabase();
        localStorage.setItem(`${self.dbName}-version`, version.toString())
        self.cacheDisabled$.next(false);
        self.cacheReady$.next(true);

      }.bind(this)).catch(function () {
        self.cacheDisabled$.next(true);
        self.cacheReady$.next(true);
      }.bind(this));

    } else {
      this.cacheDisabled$.next(true);
      this.cacheReady$.next(true);
    }
  }
  /**
   * Reset the cached IndexedDB
   *
   * @memberof CoreCacheDB
   */
  public async resetDatabase() {
    if (!this.cacheDisabled$.getValue()) {

      localStorage.removeItem(`${this.dbName}-version`);

      await this.transaction(
        'rw',
        [
          this.timestamps,
          this.allPriceTypes,
          this.allProductCodes,
          this.allCities,
          this.allDestinations,
          this.allCruiseLines,
          this.allShips,
          this.allCustomRequestTypes,
          this.allSites,
          this.allCompanies,
          this.allExperienceTypes,
          this.allOccupations,
          this.allReceiptTypes,
          this.allCountries,
          this.allPhonePrefixes,
          this.allLanguages,
          this.allSalutations,
          this.allPkgItineraryTypes,
          this.allPackageTypes,
          this.allProductCategoryTypes,
          this.allBrands,
          this.allLoyaltyDiscounts,
          this.allCurrencies,
          this.allNoteTypes,
          this.allSuiteCategories,
          this.allDeckplans,
          this.allSuiteStatus,
          this.allSuiteCapacities,
          this.allSuiteAmenities,
          this.allLoyaltyRedemptionTiers,
          this.allCertificateTypes,
          this.allManifestTypes,
          this.allPackageMealTypes,
          this.allCommissionPayoutTypes,
          this.allCommissionPayoutBkgStatus,
          this.allServiceTypes,
          this.allPromoCombinabilities,
          this.allPromoTypes,
          this.allPromoActionTypes,
          this.allPromoStatus,
          this.allCommissionPayoutBatchStatus,
          this.allSearchableSuiteCategories,
          this.allTSSites,
          this.allCancelReasons,
          this.allBestTimeToGo,
          this.allTimeZone,
          this.allMovingTypes,
          this.allPackageActivityTypes,
          this.allPromoPackageProductTypes
        ],
        () => {
          this.timestamps.clear();
          this.allPriceTypes.clear();
          this.allProductCodes.clear();
          this.allCities.clear();
          this.allDestinations.clear();
          this.allCruiseLines.clear();
          this.allShips.clear();
          this.allCustomRequestTypes.clear();
          this.allSites.clear();
          this.allCompanies.clear();
          this.allExperienceTypes.clear();
          this.allOccupations.clear();
          this.allReceiptTypes.clear();
          this.allCountries.clear();
          this.allPhonePrefixes.clear();
          this.allLanguages.clear();
          this.allSalutations.clear();
          this.allPkgItineraryTypes.clear();
          this.allPackageTypes.clear();
          this.allProductCategoryTypes.clear();
          this.allBrands.clear();
          this.allLoyaltyDiscounts.clear();
          this.allCurrencies.clear();
          this.allNoteTypes.clear();
          this.allSuiteCategories.clear();
          this.allDeckplans.clear();
          this.allSuiteStatus.clear();
          this.allSuiteCapacities.clear();
          this.allSuiteAmenities.clear();
          this.allLoyaltyRedemptionTiers.clear();
          this.allCertificateTypes.clear();
          this.allManifestTypes.clear();
          this.allCommissionPayoutTypes.clear();
          this.allCommissionPayoutBkgStatus.clear();
          this.allServiceTypes.clear();
          this.allPromoCombinabilities.clear();
          this.allPromoTypes.clear();
          this.allPromoActionTypes.clear();
          this.allPromoStatus.clear();
          this.allCommissionPayoutBatchStatus.clear();
          this.allSearchableSuiteCategories.clear();
          this.allTSSites.clear();
          this.allCancelReasons.clear();
          this.allBestTimeToGo.clear();
          this.allTimeZone.clear();
          this.allMovingTypes.clear();
          this.allPackageActivityTypes.clear();
          this.allPromoPackageProductTypes.clear();
        }
      );
    }
  }
}
