import { CoreModule } from "@app/core/models/core-constant.model";

/**
 * Reservation main root
 */
export const RESERVATION_ROOT: string = CoreModule.Reservation;

/**
 * Reservation (Wonderland API) boolean values
 */
export type ReservationApiBoolean = 'Y' | 'N';

/**
 * Reservation routes enum
 *
 * @export
 * @enum {string}
 */
export enum ReservationRoute {
  Home = 'home',
  // EntrySearch = 'entry-search',
  Search = 'search',
  SearchProduct = 'product-search',
  ShorexPrice = 'shorex-price',
  ProductDetail = 'product-detail',
  ReportViewer = 'report-viewer',
  EntityCompare = 'entity-compare',
  Booking = 'booking',
  Availability = 'product-availability',
  SuiteSelection = 'suite-selection',
  CustomPackage = 'custom-package',
  Deposit = 'deposit',
  Payment = 'payment',
  Checkout = 'checkout',
  CashReceipts = 'cash-receipts',
  ServiceSetup = 'service-setup',
  PriceSetup = 'price-setup',
  PackageSetup = 'package-setup',
  PromoSetup = 'promo-setup',
  Manifest = 'manifest',
  MyActivity = 'my-activity',
  CommissionPayout = 'commission-payout',
  GeoCity = 'geo',
  Exchange = 'exchange-rate',
  SalesAssistant = 'sales-assistant',
  Hotels = 'hotels',
  BoookingItems = 'bkg-items',
  CabinOccupancy = 'cabin-occupancy',
}

export enum ReservationAdminRoute {
  InventorySetup = 'inventory-setup',
  InteractiveDeckplan = 'interactive-deckplan',
  VillasSetup = 'villas-setup',
  VillasSetupItem = 'villas-setup-item'
}

/**
 * Reservation menu actions enum
 *
 * @export
 * @enum {string}
 */
export enum ReservationMenuActionType {
  Booking = 'booking',
  CrystalCruiseFAQ = 'crystalcruiseFAQ',
  OpenDepositNew = 'OpenDepositNew',
  OpenDepositPast = 'OpenDepositPast',
  FloatingDeposit = 'FloatingDeposit',
  FloatingDepositWC = 'FloatingDepositWC',
  AlianzLink = 'AlianzLink',
  AlianzLink2 = 'AlianzLink2',
  Ticketing = 'ticketing',
}
