import { Injectable } from '@angular/core';
import { SafeBaseObject } from '@app/core/models/base-object.models';
import { ReservationApiService } from '@app/reservation/services/reservation-api.service';
import { DateTime } from 'luxon';

export class ResVilla extends SafeBaseObject {
  static INVALID_VILLAS_ID = 0;

  villaID = ResVilla.INVALID_VILLAS_ID
  name = ''
  shortName = ''
  cityID = 0
  city = ''
  supplierID = 0
  supplierName = ''
  description = ''
  currencyCod = ''
  startDate = DateTime.invalid('empty')
  endDate = DateTime.invalid('empty')
  webSiteVisibilityFrom = DateTime.invalid('empty')
  webSiteVisibilityTo = DateTime.invalid('empty')
  isReservationOpen = false
  isWebSiteOpen = false

  constructor(data?: any) {
    super();
    this.updateData(data);
  }

  override updateData(data: any): void {
    if (!data) return;
    super.updateData(data);
  }

  get isValid(): boolean {
    return this.villaID !== ResVilla.INVALID_VILLAS_ID;
  }
}

export interface IResApiVillasSearchParameters {
  VillaID?: number
  Name?: string
  CityID?: number
  SupplierID?: number
  Currency?: string
  Description?: string
  StartDate?: DateTime
  EndDate?: DateTime

  PageNum: number,
  PageSize: number,
}

export class ResVillaDetail extends SafeBaseObject {

  villaDetail?: ResVillaSetupDetail
  villaDatePrices: ResVillaSetupDatePrices[] = []

  constructor(data?: any) {
    super();
    this.updateData(data);
  
    this.villaDetail = new ResVillaSetupDetail(data.DT0[0]);
    data.DT1.forEach((villaDatePrice: any) => {
      const mappedVillaDatePrice = new ResVillaSetupDatePrices(villaDatePrice);
      this.villaDatePrices.push(mappedVillaDatePrice)
    })
  }


}

export class ResVillaSetupDetail extends SafeBaseObject {
  villaID = 0;
  name = '';
  shortName = '';
  description = '';
  maxOccupancy = 0;
  bedrooms = 0;
  cityID = 0;
  city = '';
  currencyCod = '';
  startDate = DateTime.invalid('empty')
  endDate = DateTime.invalid('empty')
  webSiteVisibilityFrom = DateTime.invalid('empty')
  webSiteVisibilityTo = DateTime.invalid('empty')

  constructor(data?: any) {
    super();
    this.updateData(data);
  }
}

export class ResVillaSetupDatePrices extends SafeBaseObject {

  date  = DateTime.invalid('empty')
  duration = 0
  sellingPrice = 0
  currency = ''
  priceTypeID = 0
  priceTypeName = ''
  startDate = DateTime.invalid('empty')
  endDate = DateTime.invalid('empty')
  isReservationOpen = false
  isWebSiteOpen = false

  constructor(data?: any) {
    super();
    this.updateData(data);
  }
}

export interface IResApiVillaManageParams {
  VillaPriceID?: number,
  VillaID?: number,
  Date?: DateTime,
  Duration?: number,
  SellingPrice?: number,
  Currency?: string,
  PriceType?: number,
  StartDate?: DateTime,
  EndDate?: DateTime,
  Status?: string,
  Res?: string,
  Web?: string,
}

@Injectable({
  providedIn: 'root'
})
export class ResadmVillasService {

  constructor(
    private resApiService: ReservationApiService,
  ) { }

  getSearchParameters(pageNum: number, pageSize: number, villaSearchFormValues: any): IResApiVillasSearchParameters {
    const p = {
      VillaID: villaSearchFormValues.villaID ?? undefined,
      Name: villaSearchFormValues.name ?? undefined,
      CityID: villaSearchFormValues.cityID ? villaSearchFormValues.cityID.toString() : undefined,
      SupplierID: villaSearchFormValues.supplierID ?? undefined,
      Currency: villaSearchFormValues.currency ?? undefined,
      Description: villaSearchFormValues.description ?? undefined,
      StartDate: villaSearchFormValues.startDate ?? undefined,
      EndDate: villaSearchFormValues.endDate ?? undefined,

      PageNum: pageNum,
      PageSize: pageSize,
    } as IResApiVillasSearchParameters;

    return p;
  }

  search(filters: IResApiVillasSearchParameters) {
    return this.resApiService.villaSearch(filters);
  }
}
