import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SecurityCheckRequestType, getCaseInsensitiveParam } from '../models/core-constant.model';
import { EidosSecurityService } from '../services/eidos-security.service';

@Injectable({
  providedIn: 'root'
})
export class CoreTokenGuard implements CanActivate {
  constructor(
    public coreSecurityService: EidosSecurityService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const token = getCaseInsensitiveParam(route.queryParams, 'token')
    if(token) {
      if (token === 'fail') return false;

      this.coreSecurityService.setToken(token);
      this.coreSecurityService.checkRequest.emit(SecurityCheckRequestType.User);
    }
    return true;
  }

}
