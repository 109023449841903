
import { ActivatedRouteSnapshot, Params, UrlSegment } from "@angular/router";
import { DateTime } from "luxon";

/**
 * Interface of a client side application exception
 *
 * @export
 * @interface ICoreApplicationError
 */
export interface ICoreApplicationError {
  /**
   * Error message
   *
   * @type {string}
   * @memberof ICoreApplicationError
   */
  message: string;
  /**
   * Error stacktrace
   *
   * @type {string}
   * @memberof ICoreApplicationError
   */
  stack: string;
  /**
   * Path where error occurred
   *
   * @type {string}
   * @memberof ICoreApplicationError
   */
  path: string;
  /**
   * User
   *
   * @type {string}
   * @memberof ICoreApplicationError
   */
  username: string;
  /**
   * Error additional information as JSON string
   *
   * @type {string}
   * @memberof ICoreApplicationError
   */
  info: string;
}

export enum CoreModule {
  Reservation = 'reservation',
  ReservationSetup = 'reservation-setup'
}
export type EidosBootstrapColWidth = '-' | '-xs-' | '-sm-' | '-md-' | '-lg-' | '-xl-';
export type EidosBootstrapColumns = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type EidosBootstrapAlias = `col${EidosBootstrapColWidth}${EidosBootstrapColumns}`;
export const EidosSvgNameSpace = "http://www.w3.org/2000/svg";

/**
 * Eidos menu possible sort status
 *
 * @export
 * @enum {number}
 */
export enum EidosMenuOrderStatus {
  Unset = 0,
  AZ = 1,
  ZA = 2
}

/**
 * Keys enum of info saved in local storage
 *
 * @export
 * @enum {string}
 */
export enum LocalStorageKeys {
  USER = 'user',
  RAW_USER = 'raw_user',
  TOKEN = 'mybiz_token',
  REQUESTED_ROUTE = 'requested_route',
  PINNED_MENU = 'pinned_menu',
  DEBUG_MODE = 'debug_mode',
  TRAVELSTUDIO_TOKEN = 'TravelStudio_Token',
  OBJECT_DATA = 'gobj_{id}',
}

/**
 * Keys enum of info saved in session storage
 *
 * @export
 * @enum {number}
 */
export enum SessionStorageKeys {
  OBJECT_DEFINITION = 'object_definition_{id}',
  OBJECT_DATA = 'object_data_{id}_page{page}',
  ENVIRONMENT_PARAMETER = 'env_params_{name}'
}

/**
 * Eidos security check request type
 *
 * @export
 * @enum {number}
 */
export enum SecurityCheckRequestType {
  User,
  Authentication,
}

/**
 * Eidos icon types enum
 *
 * @export
 * @enum {string}
 */
export enum EidosIconType {
  MyBiz = 'image',
  Material = 'material',
  Awesome = 'awesome',
  SVG = 'svg',
  DX = 'dx'
}

/**
 * Type of event messages from child iframe enum
 *
 * @export
 * @enum {string}
 */
export enum EidosIframeBehaviourType {
  Dataloaded = 'dataloaded',
  RefreshToken = 'refresh_token',
  LoginTokenError = 'login_token_error',
  LoginTokenSuccessful = 'login_token_successful',
  Route = 'route',
  Pong = 'pong'
}

/**
 * Type of post messages from child iframe enum
 *
 * @export
 * @enum {number}
 */
export enum EidosIframeEventType {
  Command = "command",
  Version = "version",
  Event = "event"
}

/**
 * Core routes enum
 *
 * @export
 * @enum {string}
 */
export enum CoreRoute {
  ResetCredentials = 'reset',
  Login = 'login',
  Home = 'home',
  // ExternalApp = '/eidos/app',
  Readme = 'readme',
  NotFound = 'error/404',
  RegisterAccount = "/account/register"
}

/**
 * Types of profile menu action enumerations
 *
 * @export
 * @enum {string}
 */
export enum MenuActionType {
  Impersonate = 'impersonate',
  StopImpersonate = 'stopImpersonate',
  Settings = 'settings',
  ChangePassword = 'changePassword',
  Logout = 'logout',
  Debug = 'debugMode',
  Refresh = 'refresh',
  Readme = 'readme'
}

/**
 * Types of Eidos debug log severity enum
 *
 * @export
 * @enum {number}
 */
export enum EidosLogSeverity {
  Log,
  Warning,
  Error
}

/**
 * Eidos external app action types
 *
 * @export
 * @enum {number}
 */
export enum EidosExternalAppAction {
  Eidos = 'eobject',
  MyBiz = 'object',
  EmbeddedUrl = 'eurl'
}

/**
 * if input value is a valid ISO datetime, casts input to format string provided
 * If no format is provided, casts it to dd/mm/yyyy if date is at midnight, dd/mm/yyyy hh:mm otherwise
 *
 * @export
 * @param {*} value
 * @param {string} [format]
 * @return {*}  {string}
 */
export function eventuallyDisplayDateAndTime(value: any, format?: string): string {
  value = value.toString();

  const castedTime = castToDateTime(value);
  if (castedTime) {
    const defaultFormat = castedTime.toMillis() === castedTime.startOf('day').toMillis() ? 'dd/LL/yyyy' : 'dd/LL/yyyy HH:mm';
    return castedTime.toFormat(format ?? defaultFormat);
  }

  return value;
}


export function castToDateTime(value: any): DateTime | undefined {
  value = value.toString();

  const timeFromIso = DateTime.fromISO(value);
  if (timeFromIso.isValid) {
    return timeFromIso;
  } else {
    const timefromRFC2822 = DateTime.fromRFC2822(value);
    return timefromRFC2822.isValid ? timefromRFC2822 : undefined;
  }
}

export enum EidosAuthType {
  Form = 'F',
  Windows = 'W',
  Microsoft = 'M',
  Google = 'G'
}
/**
 * Returns URLSegment of Angular ActivatedRouteSnapshot
 *
 * @export
 * @param {ActivatedRouteSnapshot} r
 * @return {*}  {UrlSegment[]}
 */
export function getURLSegmentsFromActivatedRoute(r: ActivatedRouteSnapshot): UrlSegment[] {
  if (!r.parent) {
    return [];
  } else {
    return [...getURLSegmentsFromActivatedRoute(r.parent), ...r.url]
  }
};
/**
 * Returns value of a case insensitive key in params
 *
 * @export
 * @param {{[key: string]: any}} params
 * @param {string} key
 * @return {*}  {(string | undefined)}
 */
export function getCaseInsensitiveParam(params: Params, key: string): string | undefined {
  let value: any;
  for (const paramKey in params) {
    if (paramKey.toString().toLowerCase() === key.toString().toLowerCase()) {
      value = params[paramKey];
      break;
    }
  }
  return value;
}
