import { SafeBaseObject } from "@app/core/models/base-object.models"
import { DynamicApiResponse, DynamicApiResponsePaginatedModel } from "@app/core/models/dynamic-api-response.model"
import { DateTime } from "luxon"
import { ResGroups } from "./res-service.models"
import { BookingGuest, ResBooking } from "./res-booking.model"

export class ResBkgItem extends SafeBaseObject{
  itemKey=''
  itemsID:number[] = []
  roomConfHdrID:number[] = []

  bkgID = 0

  itineraryDate = DateTime.invalid('empty')
  travelEndDate = DateTime.invalid('empty')

  guestCod = ''
  guestName = ''
  guests:BookingGuest[]=[]

  packageID = 0
  productCod = ''
  productName = ''
  itemName = ''

  suiteCategoryID = 0
  suiteCategory = ''
  priceCategoryID = 0
  priceCategory = ''
  suiteNo = 0

  quantity = 0

  sellingPrice = 0
  sellingPriceTax = 0
  sellingPriceCurrency = ''

  cost = 0
  costTax = 0
  costCurrency = ''
  costBkgCurrency = 0

  bkgCurrency = ''

  margin = 0
  exchangeRate = 0


  paxGroupID = 0
  payerGroupName = ''

  status = ''

  canEdit = false
  canDelete = false

  prePost = ''

  aggregationGroupRow = 0
  aggregationDescription = ''
  aggregationField = ''
  aggregations:ResGroups[] = []

  isProduct = false

  isValid = false
  constructor(data?:any) {
    super();
    if (data) {
      super.updateData(data)
      this.updateData(data)
    }
  }

  updateData(data: any) {
    this.itemKey = `${data.ItemID}`

    this.itemsID = `${data.ItemID}`.split(',').map(itemId=>+itemId)

    this.roomConfHdrID = `${data.RoomConfHdrID}`.split(',').map(roomConfHdrID=>+roomConfHdrID)

    this.isProduct = this.productCod === 'CRU' || this.productCod === 'PKG' || this.productCod === 'CMP' || this.productCod === 'SRV'

    this.isValid = this.itemsID.length > 0
  }

  addGuest(guest?:BookingGuest) {
    if(!guest) return
    if(this.guests.find(g=>g.guestCod===guest.guestCod)) return
    this.guests.push(guest)
  }

  static createEmptyPage() {

    const emptyApiResponse = new DynamicApiResponse({
      pageNumber: 1,
      pageTotal: 0,
      recordsCount: 0,
      body: []
    })

    return new DynamicApiResponsePaginatedModel<ResBkgItem>(emptyApiResponse)
  }
}

export class ResBkgItemTotal extends SafeBaseObject{
  sellingPrice = 0
  sellingPriceCurrency = ''
  cost = 0
  costCurrency = ''
  costBkgCurrency = 0
  bkgCurrency = ''
  margin = 0

  constructor(data?:any) {
    super();
    this.updateData(data)
  }

  override updateData(data: any) {
    if (!data) return;

    super.updateData(data)
  }
}

export class ResBkgGroupedItems {
  itemsPaginated = ResBkgItem.createEmptyPage()
  groups:ResGroups[] = []
  total = new ResBkgItemTotal()

  normalizeGuests(booking:ResBooking) {
    const guests = booking.getAllGuests()
    this.itemsPaginated.content.forEach(item=>{
      item.guestCod.split(',').forEach(code=>{
        item.addGuest(guests.find(g=>g.guestCod===code))
      })
      item.guestName =  item.guests.map(g=>g.displayName()).join(', ')
    })
  }
}

export interface IResBkgItemApiParams {
  PageNum?: number
  PageSize?:number
  ProductTypeID?: number
  ProductName?: string
  GuestCod?: number[]
  ItemID?: number
}

export interface IResApiBkgItemsManage {
  ItemID?: number
  ProductCod?: string
  SuiteCategoryID?: number
  PriceCategoryID?: number
  SuiteNo?: number
  Quantity?: number
  SellingPrice?: number
  SellingPriceTax?: number
  Cost?: number
  CostTax?: number
  CostCurrency?: string
  Status?: string
  PaxGroupID?: number
  ItemGroupID?: number
}
