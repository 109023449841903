import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { isUndefined } from 'lodash';
import { catchError, filter, map, Observable, of } from 'rxjs';

import { CoreFormatService } from '@app/core/services/core-format.service';
import { EidosErrorDialogComponent } from '@common/components/dialogs/eidos-error-dialog/eidos-error-dialog.component';
import { EidosConfigService } from '@common/config/eidos-config.service';
import { IEidosModuleConfiguration } from '@common/config/environment.interface';
import {
  SearchTabsConfiguration,
  SearchTabsConfigurationParser,
} from '@common/models/core-entity-search.models';
import {
  DynamicApiResponse,
  DynamicApiResponsePaginatedModel,
} from '@common/models/dynamic-api-response.model';
import { ICoreSearchConfiguration } from '@common/models/eidos-search.model';
import { EidosEntityService } from '@common/services/eidos-entity.service';
import { EidosLogService } from '@common/services/eidos-log.service';
import { EidosSecurityService } from '@common/services/eidos-security.service';

import {
  IReservationApiCruise,
  ReservationCruise,
} from '@reservation/models/res-cruise.models';
import { IReservationEnvironment } from '@reservation/models/res-environment.interface';
import {
  IReservationApiBkgDocument,
  IReservationApiSearchBkgDocumentParams,
  ResBkgDocument,
} from '../models/res-bkg-document.model';
import {
  IReservationApiBkgHistory,
  ResBkgHistory,
} from '../models/res-bkg-notes.model';
import { IReservationSearchableSuiteCategory } from '../models/res-cached-data.models';
import {
  IReservationApiCashReceipt,
  ResCashReceipt,
} from '../models/res-cash-receipt.model';
import { IResExtendJourneyPackage, IResExtendJourneyRequest, IResExtendJourneyVoyage, ResExtendJourney, ResExtendJourneyDivisor, ResExtendJourneyPackage, ResExtendJourneyVoyage } from '../models/res-extend-journey.model';
import {
  IReservationApiGetPackageParams,
  IReservationApiPackageSetup,
  ResPackage,
  ResProduct,
} from '../models/res-package.model';
import { IResPackageSearchParams, IResProductSearchParams } from '../models/res-search.models';
import { IResSuggestedItemsRequest } from '../models/res-suggested-item.model';
import { ReservationService } from './reservation.service';
import { CoreModule } from '@app/core/models/core-constant.model';

@Injectable({
  providedIn: 'root',
})
export class ReservationEntityService extends EidosEntityService {
  constructor(
    public coreFormatService: CoreFormatService,
    public configService: EidosConfigService,
    public eidosLogService: EidosLogService,
    public dialog: MatDialog,
    public eidosSecurityService: EidosSecurityService,
    public http: HttpClient,
    private reservationService: ReservationService
  ) {
    super(
      coreFormatService,
      configService,
      eidosLogService,
      dialog,
      eidosSecurityService,
      http
    );

    this.configService.currentModulesConfig
      .pipe(
        filter(
          (modules: Array<IEidosModuleConfiguration>) =>
            !!modules.find((module) => module.moduleName === CoreModule.Reservation)
        )
      )
      .subscribe((modules) => {
        const module = modules.find(
          (module) => module.moduleName === CoreModule.Reservation
        );

        if (module) {
          const reservationModule = module as IReservationEnvironment;
          this.urlAPI = reservationModule.API.url;
          this.apiVersion = reservationModule.API.version;
        }
      });
  }
  /**
   * Get a entity search configuration
   *
   * @param {string} entity
   * @param {boolean} [local]
   * @param {string} [type='search']
   * @return {*}  {Observable<ICoreSearchConfiguration>}
   * @memberof ReservationEntityService
   */
  public getEntitySearchConfiguration(
    entity: string,
    type: string = 'search'
  ): Observable<ICoreSearchConfiguration> {
    const currentValue = this._entitySearchConfiguration[entity]
      ? this._entitySearchConfiguration[entity][type]
      : undefined;

    if (currentValue !== undefined) {
      return of(currentValue);
    } else {
      return super
        .getEntitySearchConfigurationJSON(entity, type, CoreModule.Reservation)
        .pipe(
          map<Object, ICoreSearchConfiguration>((value) => {
            const mappedConfig: ICoreSearchConfiguration =
              new SearchTabsConfigurationParser().mapConfigToModel(
                <SearchTabsConfiguration>value
              );
            if (!this._entitySearchConfiguration[entity]) {
              this._entitySearchConfiguration[entity] = {};
            }
            this._entitySearchConfiguration[entity][type] = mappedConfig;
            return mappedConfig;
          }),
          catchError((error) => {
            this.dialog.open(EidosErrorDialogComponent, {
              width: '50%',
              data: error,
            });
            return of([]);
          })
        );
    }
  }

  public getLookupSearchConfiguration(
    entity: string
  ): Observable<ICoreSearchConfiguration> {
    return this.http
      .get(`assets/json/entities/reservation/${entity}/lookup.json`)
      .pipe(
        map<Object, ICoreSearchConfiguration>((value) => {
          const mappedConfig: ICoreSearchConfiguration =
            new SearchTabsConfigurationParser().mapConfigToModel(
              <SearchTabsConfiguration>value
            );
          if (!this._entitySearchConfiguration[entity]) {
            this._entitySearchConfiguration[entity] = {};
          }
          this._entitySearchConfiguration[entity]['lookup'] = mappedConfig;
          return mappedConfig;
        }),
        catchError((error) => {
          this.dialog.open(EidosErrorDialogComponent, {
            width: '50%',
            data: error,
          });
          return of([]);
        })
      );
  }

  // #region Main and entry product search

  /**
   * Just for now... Must refactor!
   * Update 23.10.2023: ABSOLUTELY REFACTOR!!!!
   *
   * @private
   * @param {*} filters
   * @return {*}
   * @memberof ReservationEntityService
   */
  private remapCruiseSelectFilters(filters: any) {
    let other = { ...filters };

    if (!!other.guests) {
      other = Object.assign(other, other.guests);
      delete other.guests;
    }

    if (!!other.AvailableCategories && Array.isArray(other.AvailableCategories)) {
      other.AvailableCategories = other.AvailableCategories.map((c: IReservationSearchableSuiteCategory) => c.Value).join(',');
    } else {
      other.AvailableCategories = '';
    }

    return other;
  }
  /**
   * Retrieves cruises matching with filters specified
   *
   * @param {*} [filters]
   * @param {string} [endpoint='product/selectCRU']
   * @return {*}
   * @memberof ReservationEntityService
   */
  public getCruisesPaged(filters?: any, endpoint: string = 'product/selectCRU') {

    const owner = this.reservationService.currentOwner.getValue();
    if (owner.isAgent()) {
      filters.AgencyCod = owner.agencyID;
    } else if (owner.isDirect()) {
      filters.AgencyCod = "DIRECT";
    }

    return this.callDynamicAPI(endpoint, this.remapCruiseSelectFilters(filters), 'POST').pipe(
      map<DynamicApiResponse, DynamicApiResponsePaginatedModel<ReservationCruise>>((response) => {
        const paginatedModel = new DynamicApiResponsePaginatedModel<ReservationCruise>(response);
        paginatedModel.content = response.body.map(
          (cruise: IReservationApiCruise) => new ReservationCruise(cruise)
        );
        return paginatedModel;
      })
    );
  }
  /**
   * Retrieves packages matching with filters specified
   *
   * @param {*} [filters]
   * @return {*}  {Observable<DynamicApiResponsePaginatedModel<ResPackage>>}
   * @memberof ReservationEntityService
   */
  getPackagesPaged(
    filters?: IResPackageSearchParams
  ): Observable<DynamicApiResponsePaginatedModel<ResPackage>> {
    if (!isUndefined(filters)) {
      const owner = this.reservationService.currentOwner.getValue();
      if (owner.isAgent()) {
        filters.AgencyID = owner.agencyID;
      }

      // Remap isRetail boolean filter to ReservationApiBoolean
      !isUndefined(filters.IsRetail) &&
        (filters.IsRetail = filters!.IsRetail ? 'Y' : 'N');
    }

    return this.callDynamicAPI(
      'product/searchPKG',
      this.remapCruiseSelectFilters(filters),
      'POST'
    ).pipe(
      map<DynamicApiResponse, DynamicApiResponsePaginatedModel<ResPackage>>(
        (response) => {
          response.body = response.body.map(
            (pkg: IReservationApiPackageSetup) => new ResPackage(pkg)
          );
          return new DynamicApiResponsePaginatedModel<ResPackage>(response);
        }
      )
    );
  }

  /**
   * Retrieves packages matching with filters specified
   *
   * @param {*} [filters]
   * @return {*}  {Observable<DynamicApiResponsePaginatedModel<ResProduct>>}
   * @memberof ReservationEntityService
   */
  getCampPaged(
    filters?: IResProductSearchParams
  ): Observable<DynamicApiResponsePaginatedModel<ResProduct>> {
    if (!isUndefined(filters)) {
      const owner = this.reservationService.currentOwner.getValue();
      if (owner.isAgent()) {
        filters.AgencyID = owner.agencyID;
      }

      // Remap isRetail boolean filter to ReservationApiBoolean
      !isUndefined(filters.IsRetail) &&
        (filters.IsRetail = filters!.IsRetail ? 'Y' : 'N');
    }

    const prms = Object.assign(filters ?? {},{ProductCod: 'CMP,PKG,CRU'})
    return this.callDynamicAPI(
      'product/searchCMP',
      prms,
      'POST'
    ).pipe(
      map<DynamicApiResponse, DynamicApiResponsePaginatedModel<ResProduct>>(
        (response) => {
          response.body = response.body.map((p: any) => new ResProduct(p));
          return new DynamicApiResponsePaginatedModel<ResProduct>(response);
        }
      )
    );
  }
  // #endregion Main and entry product search



  /**
   * Retrieves Extend your Journey for a current booking matching with filters specified
   *
   * @param {IResSuggestedItemsRequest} [filters]
   * @return {IResSuggestedItemsRequest}
   * @memberof ReservationEntityService
   */
  public GetExtendJourneyItems(
    filters: IResExtendJourneyRequest
  ): Observable<Array<ResExtendJourney>> {
    const owner = this.reservationService.currentOwner.getValue();
    if (owner.isAgent()) {
      filters.AgencyID = owner.agencyID;
    }

    return this.callDynamicAPI('product/extendJuorney', filters, 'POST').pipe(
      map<DynamicApiResponse, Array<ResExtendJourney>>(
        (response) => {
          const r:ResExtendJourney[] = [];
          if(response.body.Packages.length>0) {
            r.push(new ResExtendJourney(ResExtendJourneyDivisor.internalType,{title:'Packages'}))
            response.body.Packages
            .forEach((product: IResExtendJourneyPackage) => r.push(new ResExtendJourney(ResExtendJourneyPackage.internalType, product)));
          }
          if(response.body.Voyages.length>0) {
            r.push(new ResExtendJourney(ResExtendJourneyDivisor.internalType,{title:'Voyages'}))
            response.body.Voyages
            .forEach((product: IResExtendJourneyVoyage) => r.push(new ResExtendJourney(ResExtendJourneyVoyage.internalType,product)));
          }
          if(r.length===0) r.push(new ResExtendJourney(ResExtendJourneyDivisor.internalType,{title:'NO DATA'}))
          return r;
        }
      ));
  }

  /**
   * Retrieves Extend your Journey for a current booking matching with filters specified
   *
   * @param {IResSuggestedItemsRequest} [filters]
   * @return {IResSuggestedItemsRequest}
   * @memberof ReservationEntityService
   */
  public GetBundlesItems(
    filters: IResExtendJourneyRequest
  ): Observable<Array<ResExtendJourney>> {
    const owner = this.reservationService.currentOwner.getValue();
    if (owner.isAgent()) {
      filters.AgencyID = owner.agencyID;
    }

    return this.callDynamicAPI('product/bundles', filters, 'POST').pipe(
      map<DynamicApiResponse, Array<ResExtendJourney>>(
        (response) => {
          const r:ResExtendJourney[] = [];
          if(response.body.Packages?.length>0) {
            r.push(new ResExtendJourney(ResExtendJourneyDivisor.internalType,{title:'Packages'}))
            response.body.Packages
            .forEach((product: IResExtendJourneyPackage) => {product.IsBundle='Y'; r.push(new ResExtendJourney(ResExtendJourneyPackage.internalType, product))});
          }
          if(response.body.Voyages?.length>0) {
            r.push(new ResExtendJourney(ResExtendJourneyDivisor.internalType,{title:'Voyages'}))
            response.body.Voyages
            .forEach((product: IResExtendJourneyVoyage) => r.push(new ResExtendJourney(ResExtendJourneyVoyage.internalType,product)));
          }
          if(r.length===0) r.push(new ResExtendJourney(ResExtendJourneyDivisor.internalType,{title:'NO DATA'}))
          return r;
        }
      ));
  }
  /**
   * Retrieves Extend your Journey for a current booking matching with filters specified
   *
   * @param {IResSuggestedItemsRequest} [filters]
   * @return {IResSuggestedItemsRequest}
   * @memberof ReservationEntityService
   */
  public GetSuggestedItems(
    filters: IResSuggestedItemsRequest
  ): Observable<DynamicApiResponsePaginatedModel<any>> {
    switch (filters.ProductCod) {

      case 'CRU':
        return this.getCruisesPaged(filters, 'booking/suggestedItemsCRU');

      case 'PKG':
        if (filters.PortsOfCall === true) {
          if (
            filters.PortsOfCallCities &&
            filters.PortsOfCallCities.length > 0
          ) {
            const cities = filters.CityTABLEID
              ? filters.CityTABLEID.split(',')
              : [];
            filters.CityTABLEID = filters.PortsOfCallCities.map((p) =>
              p.toString()
            )
              .concat(cities)
              .join(',');
          }
          delete filters.PortsOfCallCities;
        }
        return this.getPackagesPaged(filters);
      default:
        throw 'Unknown product cod!';
    }
  }
  /**
   * Retrieves cash receipts matching with filters specified
   *
   * @param {*} [filters]
   * @return {*}
   * @memberof ReservationEntityService
   */
  public getCashReceiptsPaged(filters?: any) {
    return this.callDynamicAPI('cashReceipt/select', filters, 'GET').pipe(
      map<DynamicApiResponse, DynamicApiResponsePaginatedModel<ResCashReceipt>>(
        (response) => {
          const paginatedModel =
            new DynamicApiResponsePaginatedModel<ResCashReceipt>(response);
          paginatedModel.content = response.body.map(
            (cash: IReservationApiCashReceipt) => new ResCashReceipt(cash)
          );
          return paginatedModel;
        }
      )
    );
  }
  /**
   * Retrieves booking documents matching with filters specified
   *
   * @param {IReservationApiSearchBkgDocumentParams} [filters]
   * @return {*}
   * @memberof ReservationEntityService
   */
  public getBkgDocuments(filters?: IReservationApiSearchBkgDocumentParams) {
    return this.callDynamicAPI('invoiceForBkg/select', filters, 'GET').pipe(
      map<DynamicApiResponse, DynamicApiResponsePaginatedModel<ResBkgDocument>>(
        (response) => {
          const paginatedModel =
            new DynamicApiResponsePaginatedModel<ResBkgDocument>(response);
          paginatedModel.content = response.body.map(
            (cash: IReservationApiBkgDocument) => new ResBkgDocument(cash)
          );
          return paginatedModel;
        }
      )
    );
  }
  /**
   * Retrieves booking history for the booking with params.bkgID as BkgID
   *
   * @param {number} BkgID
   * @return {*}  {Observable<any[]>}
   * @memberof ReservationEntityService
   */
  public getBookingHistory(params: {
    bkgID: number;
  }): Observable<ResBkgHistory[]> {
    return this.callDynamicAPI('bookingHistory/select', {
      BkgID: params.bkgID,
    }).pipe(
      map<DynamicApiResponse, Array<ResBkgHistory>>((response) => {
        return response.body.map(
          (h: IReservationApiBkgHistory) => new ResBkgHistory(h)
        );
      })
    );
  }

  /**
   * Retrieves package headers matching with filters specified for package setup
   *
   * @param {IReservationApiGetPackageParams} [params]
   * @return {*}  {Observable<ResPackage[]>}
   * @memberof ReservationEntityService
   */
  public getPackagesSetupHeader(
    params?: IReservationApiGetPackageParams
  ): Observable<DynamicApiResponsePaginatedModel<ResPackage>> {
    return this.callDynamicAPI('packageHeader/select', params).pipe(
      map<DynamicApiResponse, DynamicApiResponsePaginatedModel<ResPackage>>(
        (response) => {
          const paginatedModel =
            new DynamicApiResponsePaginatedModel<ResPackage>(response);
          paginatedModel.content = response.body.map(
            (p: IReservationApiPackageSetup) => new ResPackage(p)
          );
          return paginatedModel;
        }
      )
    );
  }

  // public getPromo(
  //   params?: IPromoParams
  // ): Observable<DynamicApiResponsePaginatedModel<ResPromo>> {
  //   if (Array.isArray(params?.priceTypeId)) params!.priceTypeId = params!.priceTypeId.join(',');
  //   if (params?.priceTypeId === '') {
  //     delete params.priceTypeId;
  //   }
  //   return this.callDynamicAPI('promo/search', params, 'POST').pipe(
  //     map<DynamicApiResponse, DynamicApiResponsePaginatedModel<ResPromo>>(
  //       (response) => {
  //         const paginatedModel = new DynamicApiResponsePaginatedModel<ResPromo>(
  //           response
  //         );
  //         paginatedModel.content = response.body.map(
  //           (p: IReservationApiPromoSetup) => new ResPromo(p)
  //         );
  //         return paginatedModel;
  //       }
  //     )
  //   );
  // }
}
