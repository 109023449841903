import { Component, OnInit, isDevMode } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { MatDialog } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { filter, takeUntil } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import _ from 'lodash';

import packageJson from '../../../../../package.json';
import gitVersion from '../../../../../git-version.json';
import { environment } from 'src/environments/environment';

import { EidosConfigService } from '@common/config/eidos-config.service';
import { IEidosEnvironment, IEidosIcon, IEidosDefaultProfileBadge, IEidosMenuAction, IEidosNotificationConfiguration, IEidosLoginConfiguration, IEidosWindowsAuth, IEidosModuleConfiguration, IEidosExternalIcon, IEidosFormats, IEidosModuleAPIConfiguration, IEidosExchangeRateEmail } from '@common/config/environment.interface';
import { EidosIconType, EidosLogSeverity } from '@common/models/core-constant.model';
import { EidosExternalAppService } from '@common/eidos-external-app/eidos-external-app.service';
import { EidosLogService } from '@common/services/eidos-log.service';
import { EidosUtilityService } from '@common/services/eidos-utility.service';
import { EidosExternalApp } from '@common/models/eidos-external-app.model';
import { IEidosExternalAppEventData, IEidosExternalAppVersion } from '@common/models/eidos-external-app.interface';
import { MyBizVersionApiResponse } from '@common/models/mybiz.model';
import { EidosBaseComponent } from '@common/components/eidos-base.component';
import { EidosAlertDialogComponent } from '@common/components/eidos-dialogs/eidos-alert-dialog/eidos-alert-dialog.component';
import { IEidosLinksConfiguration } from '@common/config/environment.interface';

@Component({
  selector: 'eidos-configuration-viewer',
  templateUrl: './eidos-configuration-viewer.component.html',
  styleUrls: ['./eidos-configuration-viewer.component.scss']
})
export class EidosConfigurationViewerComponent extends EidosBaseComponent implements OnInit {

  protected isDevMode = isDevMode;
  protected objectEntries = Object.entries;
  protected typeOf = (item: any) => typeof item;

  /**
   * App version
   *
   * @protected
   * @type {{ [key: string]: string }}
   * @memberof EidosConfigurationViewerComponent
   */
  protected Version: { [key: string]: string } = {};
  /**
   * External apps versions
   *
   * @type {Array<IEidosExternalAppVersion>}
   * @memberof EidosConfigurationViewerComponent
   */
  protected versions: Array<IEidosExternalAppVersion> = [];
  /**
   * MyBiz app versions
   *
   * @type {(MyBizVersionApiResponse | undefined)}
   * @memberof EidosConfigurationViewerComponent
   */
  protected myBizVersion: MyBizVersionApiResponse | undefined;
  /**
   * Env
   *
   * @memberof EidosConfigurationViewerComponent
   */
  protected Environment = environment;
  /**
   * Current configuration
   *
   * @type {IEidosEnvironment}
   * @memberof EidosConfigurationViewerComponent
   */
  protected config: IEidosEnvironment;
  /**
   * Fake configuration object of concrete class
   *
   * @type {EidosEnvironment}
   * @memberof EidosConfigurationViewerComponent
   */
  protected fakeConfig: EidosEnvironment;
  /**
   * External apps to see in configurations
   *
   * @type {Array<EidosExternalApp>}
   * @memberof EidosConfigurationViewerComponent
   */
  protected apps: Array<EidosExternalApp> = [];

  constructor(
    private sanitizer: DomSanitizer,
    private externalAppService: EidosExternalAppService,
    private configService: EidosConfigService,
    private utilityService: EidosUtilityService,
    private eidosLogService: EidosLogService,
    public translocoService: TranslocoService,
    private dialog: MatDialog
  ) {
    super();
    this.fakeConfig = new EidosEnvironment(this.sanitizer);
    this.config = this.configService.DEFAULT_CONFIG;
  }

  ngOnInit(): void {

    this.Version["Version"] = packageJson.version;
    this.Version["Repo"] = gitVersion.Repo;
    this.Version["Last commit hash"] = gitVersion.LastCommitHash;
    this.Version["Last commit"] = gitVersion.LastCommitMessage;
    this.Version["Date and time"] = gitVersion.LastCommitTime;
    this.Version["Author"] = gitVersion.LastCommitAuthor;
    this.Version["Commit #"] = gitVersion.VersionNumber;

    this.configService.currentConfig.subscribe(config => this.config = config);

    // Subscribe for MyBiz versions
    this.utilityService.getMyBizVersion().subscribe(response => {
      this.myBizVersion = response;
    });

    // Subscribe for extapps
    this.externalAppService.apps
      .pipe(
        filter(apps => !!apps),
        takeUntil(this.subscription)
      )
      .subscribe(apps => this.handleExternalAppsConfig(apps ?? []));

    // Subscribe for other external apps versions
    this.externalAppService.ExternalApplicationEvent
      .pipe(takeUntil(this.subscription))
      .subscribe((event: IEidosExternalAppEventData) => {
        this.eidosLogService.logDebug(EidosLogSeverity.Log, `iframe master event: name=${event.name}`);
        switch (event.name) {
          case 'versionResponse':
            let appToUpdate = this.versions.find(app => app.source == event.source);
            if (appToUpdate) {
              appToUpdate.version = event.parameters.version;
            } else {
              appToUpdate = {
                source: event.source,
                version: event.parameters.version
              };
              this.versions.push(appToUpdate);
            }
            break;
          default:
            break;
        }
      });
  }
  /**
   * Requires versions and handle duplicates for external apps
   *
   * @private
   * @param {EidosExternalApp[]} apps
   * @memberof EidosConfigurationViewerComponent
   */
  private handleExternalAppsConfig(apps: EidosExternalApp[]) {
    this.apps = apps;
    this.externalAppService.requireAppVersions();
    this.apps.forEach(app => {
      if (app.isDuplicated) {
        this.dialog.open(EidosAlertDialogComponent, {
          width: '320px',
          data: {
            info: `The app '${app.source}' has a duplicated configuration: only one iframe has been created for it.\r\nPlease check config file and external apps DB configuration to remove duplicates.`
          }
        });
      }
    });
  }

  /**
  * if input value is a GIT formatted datetime, casts input to formatted string
   *
   * @param {*} value
   * @return {*}
   * @memberof EidosConfigurationViewerComponent
   */
  public eventuallyDisplayDateAndTime(value: any) {
    value = value.toString();
    const timeFromFormat = DateTime.fromFormat(value, "ccc LLL d TT yyyy ZZZ");
    if (timeFromFormat.isValid) {
      const dateFormat = timeFromFormat.toMillis() === timeFromFormat.startOf('day').toMillis() ? 'dd/LL/yyyy' : 'dd/LL/yyyy HH:mm';
      value = timeFromFormat.toFormat(dateFormat);
    }
    return value;
  }
}

/**
 * Concrete class used to implement the configuration interface
 *
 * @class EidosEnvironment
 * @implements {IEidosEnvironment}
 */
class EidosEnvironment implements IEidosEnvironment {
  production: boolean;
  baseUrl: string;
  urlAPI: string;
  reportAPIUrl: string;
  reportInvokeAction: string;
  urlAuthAPI: string;
  myBizLegacyConfiguration: EidosExternalApp;
  embeddedUrl: EidosExternalApp;
  externalApps: Array<EidosExternalApp>;
  urlMessagingService: string;
  ExtendedFieldHeight: number;
  ExtendedFieldPadding: number;
  ExtendedFieldMargin: number;
  loginConfiguration: IEidosLoginConfiguration;
  applicationTitle: string;
  microsoftAuth: IEidosWindowsAuth;
  googleAuth: IEidosWindowsAuth;
  tokenOnHeader: boolean;
  windowsAuth: IEidosWindowsAuth;
  notificationConfiguration: EidosNotificationConfig;
  logo: IEidosIcon;
  defaultProfileBadge: IEidosDefaultProfileBadge;
  landingPage: string;
  externalAppsRoot: string;
  links: IEidosLinksConfiguration[];
  menuActions: Array<IEidosMenuAction>;
  errorBackground: string;
  hideCompanyName: boolean;
  hideMyBizMenu:boolean;
  enableOrganization: boolean;
  modules: Array<EidosModuleConfiguration>;
  externalIcons: Array<IEidosExternalIcon>;
  exchangeRateEmail: IEidosExchangeRateEmail;
  formats: IEidosFormats;
  cache: {
    version: number;
    enabled: boolean,
    cacheValidityMinutes: number
  };

  constructor(private sanitizer: DomSanitizer) {
    this.production = false;
    this.baseUrl = '';
    this.urlAPI = '';
    this.urlAuthAPI = '';
    this.reportAPIUrl = '';
    this.reportInvokeAction = '';
    this.urlMessagingService = '';
    this.ExtendedFieldHeight = 0;
    this.myBizLegacyConfiguration = new EidosExternalApp(this.sanitizer);
    this.embeddedUrl = new EidosExternalApp(this.sanitizer);
    this.externalApps = [new EidosExternalApp(this.sanitizer)];
    this.ExtendedFieldPadding = 0;
    this.ExtendedFieldMargin = 0;
    this.loginConfiguration = new EidosLoginConfiguration();
    this.applicationTitle = '';
    this.microsoftAuth = new EidosWindowsAuth();
    this.googleAuth = new EidosWindowsAuth();
    this.tokenOnHeader = false;
    this.windowsAuth = new EidosWindowsAuth();
    this.notificationConfiguration = new EidosNotificationConfig();
    this.logo = new EidosIcon();
    this.defaultProfileBadge = new EidosDefaultProfileBadge();
    this.landingPage = '';
    this.externalAppsRoot = '';
    this.links = [new EidosLinksConfiguration()];
    this.menuActions = [new MenuAction()];
    this.errorBackground = '';
    this.hideCompanyName = false;
    this.hideMyBizMenu = false;
    this.enableOrganization = false;
    this.modules = [new EidosModuleConfiguration()];
    this.externalIcons = [new EidosExternalIcon()];
    this.exchangeRateEmail = new EidosExchangeRateEmail();
    this.formats = {
      defaultCurrency: '',
      dateAndTime: '',
      dateFmt: '',
      dateAndMonth: '',
      dateAndMonthAndYear: '',
      dateAndMonthAndYearAndTime: '',
      dateQueryParams: '',
      dateQueryApiFormat: '',
      dateStandardLocaleOpts: { locale: '' },
      currencies: { },
      amountStandardFormatOpts: '',
      amountStandardLocaleOpts: '',
    };
    this.cache = {
      version: 0,
      enabled: false,
      cacheValidityMinutes: 0
    };
  }
  applicationBackground?: string | undefined;
  hideTopbar?: boolean | undefined;
}

class EidosLinksConfiguration implements IEidosLinksConfiguration {
  key: string;
  template: string;

  constructor() {
    this.key = '';
    this.template = '';
  }
}

class EidosExternalIcon implements IEidosExternalIcon {
  code: string;
  type: string;
  url: string;
  absoluteUrl?: boolean;

  constructor() {
    this.code = '';
    this.type = '';
    this.url = '';
    this.absoluteUrl = false;
  }
}
class EidosExchangeRateEmail implements IEidosExchangeRateEmail {
  object: string;
  body: string;
  to: string[];
  cc: string[]

  constructor() {
    this.object = '';
    this.body = '';
    this.to = [];
    this.cc = []
  }
}

class EidosWindowsAuth implements IEidosWindowsAuth {
  enabled: boolean;
  authorizationUrl: string;

  constructor() {
    this.enabled = false;
    this.authorizationUrl = '';
  }
}

class EidosIcon implements IEidosIcon {
  iconType: EidosIconType;
  iconCode: string;
  iconSize: number;
  inverseColor: boolean | undefined;
  iconUrl?: string;
  iconCodeSafeUrl?: SafeResourceUrl;

  constructor() {
    this.iconType = EidosIconType.SVG;
    this.iconCode = '';
    this.iconSize = 0;
    this.inverseColor = true;
  }
}

class EidosDefaultProfileBadge implements IEidosDefaultProfileBadge {
  defaultBadgeColorPrimary: string;
  defaultBadgeColorSecondary: string;

  constructor() {
    this.defaultBadgeColorPrimary = '';
    this.defaultBadgeColorSecondary = '';
  }
}
class MenuAction implements IEidosMenuAction {
  id: string;
  order: number;
  icon: string;
  label: string;
  labelCod: string;
  labelValues: string[];
  enabled: boolean;

  constructor() {
    this.id = "";
    this.order = 1;
    this.icon = "";
    this.label = "";
    this.labelCod = "";
    this.enabled = false;
    this.labelValues = [''];
  }
}
class EidosNotificationConfig implements IEidosNotificationConfiguration {
  enabled: boolean;
  pollingInterval: number;

  constructor() {
    this.enabled = false;
    this.pollingInterval = 1;
  }
}

class EidosLoginConfiguration implements IEidosLoginConfiguration {
  public assetsPath: string;
  public logoName: string;
  public wallpaperNames: string | Array<string>;

  constructor() {
    this.assetsPath = '';
    this.logoName = '';
    this.wallpaperNames = [''];
  }
}

class EidosModuleConfiguration implements IEidosModuleConfiguration {
  public moduleName: string;
  public baseUrl: string;
  public hideTopbar: boolean;
  public hideSidebar: boolean;
  public local: boolean;
  public API: IEidosModuleAPIConfiguration;
  public crossReferenceURL: { [key: string]: string; };

  constructor() {
    this.moduleName = '';
    this.baseUrl = window.location.origin;
    this.hideSidebar = false;
    this.hideTopbar = false;
    this.local = false;
    this.API = new EidosModuleAPIConfiguration();
    this.crossReferenceURL = {};
  }
}

class EidosModuleAPIConfiguration implements IEidosModuleAPIConfiguration {
  public url: string;
  public staticUrl: string;
  public version: string;
  public headers: { [key: string]: string };

  constructor() {
    this.url = '';
    this.staticUrl = '';
    this.version = '';
    this.headers = {};
  }
}
