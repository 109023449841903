import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDynamicApiResponse } from '@app/core/models/dynamic-api-response.model';
import { ResFinancialSplit } from '@app/reservation/models/res-booking.model';
import { ReservationApiService } from '@app/reservation/services/reservation-api.service';
import { ReservationService } from '@app/reservation/services/reservation.service';

@Component({
  selector: 'app-res-financial-split-dialog',
  templateUrl: './res-financial-split-dialog.component.html',
  styleUrls: ['./res-financial-split-dialog.component.scss']
})
export class ResFinancialSplitDialogComponent {
  constructor(
    private reservationApiService: ReservationApiService,
    private reservationService: ReservationService,
    private dialogRef: MatDialogRef<ResFinancialSplitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  financialSplit: ResFinancialSplit[] = []
  loading: boolean = false;

  ngOnInit() {
    this.loading = true;
    this.reservationApiService.getFinancialSplit(this.data?.bkgID, this.data?.section).subscribe({
      next: (response) => {
        this.financialSplit = response;
        this.loading = false;
      },
      error: (err: IDynamicApiResponse) => {
        this.loading = false
        this.reservationService.utilityDialogs.error({
          message: 'Error. Financial Split Not Loaded.',
          error: err?.Errors?.map((e: any) => e.ErrorDesc),
          title: `ERROR - Financial Split`,
          size: '380px',
        });
      }
    })
  }

  close() {
    this.dialogRef.close();
  }

}
