import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs';

import { EidosConfigService } from '@common/config/eidos-config.service';
import { IEidosIcon, IEidosLoginConfiguration } from '@common/config/environment.interface';
import { EidosIconType, CoreRoute, SecurityCheckRequestType } from '@common/models/core-constant.model';
import { EidosSecurityService } from '@common/services/eidos-security.service';

@Component({
  selector: 'eidos-login-token',
  templateUrl: './eidos-login-token.component.html',
  styleUrls: ['./eidos-login-token.component.scss']
})
export class EidosLoginTokenComponent implements OnInit {
  /**
   * Error message
   *
   * @type {string}
   * @memberof EidosLoginTokenComponent
   */
  public message = '';
  /**
   * User name request access
   *
   * @type {string}
   * @memberof EidosLoginTokenComponent
   */
  public userName = '';
  /**
   * Error state
   *
   * @type {string}
   * @memberof EidosLoginTokenComponent
   */
  public isOnError = false;
  /**
   * Logo icon configuration
   *
   * @type {IEidosIcon}
   * @memberof EidosLoginTokenComponent
   */
  public logo: IEidosIcon;
  /**
   * Ok icon configuration
   *
   * @type {IEidosIcon}
   * @memberof EidosLoginTokenComponent
   */
  public okIcon: IEidosIcon = {
    iconType: EidosIconType.Material,
    iconCode: "block",
    iconSize: 4,
  };
  /**
   * Path to login wallpaper image
   *
   * @type {string}
   * @memberof EidosLoginTokenComponent
   */
  public backgroundImagePath: string = '';
  /**
  * Path to login logo image
  *
  * @type {string}
  * @memberof EidosLoginTokenComponent
  */
  public logoImagePath: string = '';
  /**
   * Login configuration
   *
   * @private
   * @type {IEidosLoginConfiguration}
   * @memberof EidosLoginTokenComponent
   */
  private loginConfiguration: IEidosLoginConfiguration;

  constructor(
    private route: ActivatedRoute
    , private router: Router
    , private coreSecurityService: EidosSecurityService
    , private eidosConfigService: EidosConfigService
  ) {
    this.logo = this.eidosConfigService.DEFAULT_CONFIG.logo;
    this.loginConfiguration = this.eidosConfigService.DEFAULT_CONFIG.loginConfiguration;
    if (this.loginConfiguration?.logoName?.length > 0) {
      this.logoImagePath = this.loginConfiguration.assetsPath + this.loginConfiguration.logoName;
    }
    const homeWallpaper = this.loginConfiguration.wallpaperNames;
    if (homeWallpaper) {
      if (Array.isArray(homeWallpaper)) {
        this.backgroundImagePath = this.loginConfiguration.assetsPath + homeWallpaper[Math.floor(Math.random() * homeWallpaper.length)];
      }
      switch (typeof homeWallpaper) {
        case "string":
          this.backgroundImagePath = this.loginConfiguration.assetsPath + homeWallpaper;
          break;
        default:
          break;
      }
    }
  }

  ngOnInit(): void {
    const token = this.route.snapshot.paramMap.get('token') ?? 'fail';
    const requestUrl = this.route.snapshot.paramMap.get('r');
    if(requestUrl) this.coreSecurityService.setRequestedRoute(requestUrl)

    if (token === 'fail') {
      this.failure();
      return;
    }

    this.coreSecurityService.setToken(token);
    this.coreSecurityService.checkRequest.emit(SecurityCheckRequestType.User);
    this.success();
  }
  /**
   * Go to login handler
   *
   * @memberof EidosLoginTokenComponent
   */
  public goToLogin() {
    this.router.navigate([CoreRoute.Login]);
  }
  /**
   * Login successful handler
   *
   * @private
   * @memberof EidosLoginTokenComponent
   */
  private success() {

    this.coreSecurityService.currentLoggedUser.pipe(filter(user => user.isValid)).subscribe({
      next:(_)=>{

        // Check if a route has been requested before login redirection
        const requestedRoute = this.coreSecurityService.getRequestedRoute();
        this.coreSecurityService.clearRequestedRoute();

        if (requestedRoute && requestedRoute !== '/') {

          window.location.href = window.location.origin + requestedRoute;

        } else {
          const redirect = this.route.snapshot.queryParamMap.get('r');
          setTimeout(()=>{
            if (redirect) {
              this.router.navigate(redirect.split('/'));
            } else {
              this.router.navigate([CoreRoute.Home]);
            }
          })
        }

      }
    });


  }
  /**
   * Login fail handler
   *
   * @private
   * @memberof EidosLoginTokenComponent
   */
  private failure(): void {
    this.isOnError = true;
    this.message = this.route.snapshot.paramMap.get('m') ?? 'INVALIDACCESS';
    this.userName = this.route.snapshot.paramMap.get('u') ?? '';
  }
}
