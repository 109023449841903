import { DateTime } from 'luxon';
import { merge } from 'lodash';

import { CoreFormatService } from '@app/core/services/core-format.service';
import { BaseObject, SafeBaseObject } from '@common/models/base-object.models';

import { ReservationService } from '../services/reservation.service';
import { IReservationServiceType } from './res-cached-data.models';
import { IReservationApiParametersBookCamp, IReservationApiParametersSuite } from './res-booking.model';

export class ReservationServiceType extends SafeBaseObject {
  serviceTypeID?: number;
  serviceTypeName?: string;
  serviceTypeDescription?: string;
  productCod?: string;
  isSelected: boolean;
  guestConstraint?: boolean;
  tabsLocation?: string;
  onBoardUse?: boolean;
  hasCruise?: boolean;

  get displayName() {
    return `${this.serviceTypeName} - ${this.serviceTypeDescription}`
  }
  constructor(data: IReservationServiceType) {
    super();
    this.isSelected = false;
    if (data) {
      this.updateData(data);
    }
  }

  override updateData(data: IReservationServiceType): void {
    this.addMangledProperty(data);
    this.addBooleanProperty('guestConstraint', data, 'GuestConstraint');
    this.addBooleanProperty('onBoardUse', data, 'OnBoardUse');
    this.addBooleanProperty('hasCruise', data, 'HasCruise');
  }
}

export interface IReservationApiServiceType {
   ServiceTypeName?: string,
   ServiceTypeID?: number,
   BkgID?: number
}

export interface IReservationApiSupplier {
  SupplierID: number;
  SupplierName: string;
  CityAndSupplierName: string;
  Phone?: number;
  Email?: string;
  MainContact?: string;
  CountryCod?: string;
  CountyID?: number;
  StateID?: number;
  SupplierAccountCod?: string;
  Buyer?: string;
  Status: string;
  Country?: string;
  Address?: string;
  Currency?: string;
  CityID?: number;
  City?: string;
}

export class ReservationSupplier implements IReservationApiSupplier {
  SupplierID: number = -1;
  SupplierName: string = '';
  Phone?: number;
  Email?: string;
  MainContact?: string;
  CountryCod?: string;
  CountyID?: number;
  StateID?: number;
  SupplierAccountCod?: string;
  Buyer?: string;
  Status: string = 'A';
  Country?: string;
  Address?: string;
  Currency?: string;
  CityID?: number;
  City?: string;

  get CityAndSupplierName(): string {
    return (!!this.City ? this.City + ' - ' : '') + this.SupplierName;
  }

  constructor(data?: IReservationApiSupplier) {
    if (data) {
      merge(this, data);
    }
  }
}

export interface IReservationApiGetSuppliers {
  SupplierID?: number,
  SupplierName?: string,
  AllSuppliers?: string
  ServiceTypeID?: number
  companyID_OVERWRITE?: number
  PageSize?: number
}
export interface IReservationOptionDetail {
  serviceTypeID?: number;
  serviceID?: number;
  optionID?: number;
  sellingPrice?: number,
  date?: DateTime,
  formattedPrice?: string,
  formattedDate?: string,
  formattedCost?: string,
  formattedSellingTax?: string,
  priceCurrency?: string,
  valueType?: string,
  isIncluded?: boolean,
  onHoldQTY?: number,
  isPercentage?: boolean,
  onRequest?: boolean
  quantityToAdd?: number
}

export class ReservationServiceOption extends BaseObject {
  serviceTypeID?: number;
  serviceTypeName?: string;
  serviceTypeDescription?: string;
  guestConstraint?: boolean;
  serviceID?: number;
  serviceLongName?: string;
  serviceShortName?: string;
  serviceName?: string;
  serviceDescription?: string;
  supplierName?: string;
  optionID?: number;
  optionCod?: string;
  minPax?: number;
  maxPax?: number;
  needMessage?: boolean;
  csFlag?: boolean;
  optionName?: string;
  optionShortName?: string;
  optionDesc?: string;
  estimatedCost?: number;
  cost?: number;
  costTypeID?: number;
  costCurrency?: string;
  priceSetUp?: string;
  priceSetupID?: number;
  sellingPrice?: number;
  sellingPriceCurrency?: string;
  sellingTax?: number;
  priceCurrency?: string;
  currency?: string;
  margin?: number;
  date?: DateTime;
  selfIncluded?: boolean;
  iNCServiceTypeID?: number;

  _allAvailableOptions: ReservationServiceOption[] = [this];
  get allAvailableOptions(): ReservationServiceOption[] {
    return this._allAvailableOptions;
  }
  set allAvailableOptions(value: ReservationServiceOption[]) {
    this._allAvailableOptions = value;
    this.itineraryDateStart = [...this.availableDates][0];
    this.itineraryDateEnd = [...this.availableDates].at(-1);
  }

  _itineraryDateStart?: DateTime;
  _itineraryDateEnd?: DateTime;
  valueType?: 'Amount' | 'Percentage';
  formattedPrice?: string;
  formattedSellingTax?: string;
  formattedCost?: string;
  formattedDate?: string;
  startDate?: DateTime;
  endDate?: DateTime;
  message: string = '';
  isIncluded?: boolean;
  prePostFlag?: string
  _onCheckIn?: boolean;
  _onCheckOut?: boolean;
  availableOnCheckIn?: boolean;
  availableOnCheckOut?: boolean;
  onBoardUse?: boolean; // provided by the service type and setted by the FE
  CommissionableSO?: boolean;
  onHoldQTY?: number;
  guestCod?: string;
  onRequest?: boolean;
  quantityToAdd?: number = 1;

  availableSelectedOptionsDetail: IReservationOptionDetail[] = []

  coreFormatService?: CoreFormatService;

  // if the option is inclusive itineraryDateStart and itineraryDateEnd are checkIn and checkOut dates, otherwise they are the selected daterange

  constructor(
    data: IReservationApiServiceOption,
    coreFormatService?: CoreFormatService,
  ) {
    super(data);
    this.coreFormatService = coreFormatService
    this.updateData(data);
  }

  override updateData(
    data: IReservationApiServiceOption,
  ): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('date', data, 'Date');
    this.addDateTimeProperty('startDate', data, 'StartDate');
    this.addDateTimeProperty('endDate', data, 'EndDate');
    this.addBooleanProperty('needMessage', data, 'NeedMessage');
    this.addBooleanProperty('csFlag', data, 'CsFlag');
    this.addBooleanProperty('isIncluded', data, 'IsIncluded');
    this.addBooleanProperty('commissionableSO', data, 'CommissionableSO');
    this.addBooleanProperty('onBoardUse', data, 'OnBoardUse');
    this.addBooleanProperty('selfIncluded', data, 'SelfIncluded');
    this.addBooleanProperty('onRequest', data, 'OnRequest');

    this.formattedPrice = this.formatOptionPrices(
      'sellingPrice',
      this.coreFormatService
    );
    this.formattedSellingTax = this.formatOptionPrices(
      'sellingTax',
      this.coreFormatService
    );
    this.formattedCost = this.formatOptionPrices('cost', this.coreFormatService);
    this.formattedDate = this.formatOptionDate(this.coreFormatService);

    this.recalculateSelectedOptionsDetail();
  }

  get onCheckIn(): boolean | undefined {
    return this._onCheckIn;
  }
  set onCheckIn(value: boolean | undefined) {
    this._onCheckIn = value;
    if (value) this.itineraryDateStart = this.availableDates[0];
    else if (this.isIncluded) this.itineraryDateStart = undefined;
  }

  get onCheckOut(): boolean | undefined {
    return this._onCheckOut;
  }
  set onCheckOut(value: boolean | undefined) {
    this._onCheckOut = value;
    if (value) this.itineraryDateEnd = this.availableDates.at(-1);
    else if (this.isIncluded) this.itineraryDateEnd = undefined;
  }

  get itineraryDateStart(): DateTime | undefined {
    return this._itineraryDateStart;
  }
  set itineraryDateStart(value: DateTime | undefined) {
    this._itineraryDateStart = value;
    this.recalculateSelectedOptionsDetail()

  }

  get itineraryDateEnd(): DateTime | undefined {
    return this._itineraryDateEnd;
  }
  set itineraryDateEnd(value: DateTime | undefined) {
    this._itineraryDateEnd = value;
    this.recalculateSelectedOptionsDetail()
  }


  public get isPercentage(): boolean {
    return this.valueType?.toLowerCase() === 'percentage';
  }

  public get isAmount(): boolean {
    return this.valueType?.toLowerCase() === 'amount';
  }

  public get isPre(): boolean {
    return this.prePostFlag?.toLowerCase() === 'pre';
  }

  public get isPost(): boolean {
    return this.prePostFlag?.toLowerCase() === 'post';
  }

  public get isPrePost(): boolean {
    return this.prePostFlag?.toLowerCase() === 'all';
  }

  public get availableForAllGuests(): boolean {
    return this.guestCod?.toLowerCase() === 'all';
  }

  public get availableGuestCods(): string[] {
    return this.guestCod?.split(',') || [];
  }

  public get availableDates(): DateTime[] {
    return this.allAvailableOptions.map((o) => o.date).filter(d => d?.isValid).sort((a, b) => +(a ?? 0) - +(b ?? 0)) as DateTime[];
  }

  public formatOptionPrices(
    type: string,
    coreFormatService?: CoreFormatService
  ): string {
    if (!this[type] && this[type] !== 0) return '';

    if (this.isPercentage) return `${this[type]}%`;

    if (!coreFormatService) return typeof this[type] === 'number' ? this[type].toString() : this[type];

    const currency = type == 'cost' ? this.costCurrency : this.priceCurrency;

    return coreFormatService.CurrencyAmount(this[type], currency);
  }

  public formatOptionDate(coreFormatService?: CoreFormatService): string {
    if (!this.date || !coreFormatService) return '';
    return coreFormatService.DateToDefaultDateFormat(this.date);
  }

  public formatOptionAvailableDates() {
    return this.availableDates.map((d) => d.toJSDate());
  }

  public get hasOnlyOneDateAvailable(): boolean {
    return this.availableDates.length === 1;
  }

  public getPriceByDate(date: DateTime): number {
    const option = this.getAvailableOptionByDate(date);
    return option ? this.getFormattedPriceNumber(option) : 0;
  }

  public getAvailableOptionByDate(date: DateTime): ReservationServiceOption | undefined {
    if (!date?.isValid) return undefined;
    return this.allAvailableOptions.find((o: ReservationServiceOption) => +(o.date ?? -1) === +date);
  }

  public getItineraryDateRange(): DateTime[] {
    if (!this.itineraryDateStart) return [];
    return ReservationService.buildDateRange(this.itineraryDateStart, this.itineraryDateEnd);
  }

  public getAvailableSelectedOptions(): ReservationServiceOption[] {
    if (!this.allAvailableOptions?.length) return [];

    if (this.isIncluded && !this.onBoardUse) {
      if (!this.itineraryDateStart && !this.itineraryDateEnd) return [];
      if (this.onCheckIn && this.onCheckOut && this.itineraryDateStart && this.itineraryDateEnd) {
        return [this.getAvailableOptionByDate(this.itineraryDateStart), this.getAvailableOptionByDate(this.itineraryDateEnd)].filter((o) => !!o) as ReservationServiceOption[];
      }
      if (this.onCheckIn && this.itineraryDateStart) {
        return [this.getAvailableOptionByDate(this.itineraryDateStart)].filter((o) => !!o) as ReservationServiceOption[];
      }
      if (this.onCheckOut && this.itineraryDateEnd) {
        return [this.getAvailableOptionByDate(this.itineraryDateEnd)].filter((o) => !!o) as ReservationServiceOption[];
      }
      return [];
    }

    return this.getItineraryDateRange().map((d) => this.getAvailableOptionByDate(d)).filter((o) => !!o) as ReservationServiceOption[];
  }

  public getAvailableSelectedOptionsDetail(): IReservationOptionDetail[] {
    return this.getAvailableSelectedOptions().map(option => ({ sellingPrice: option.sellingPrice, formattedPrice: option.formattedPrice, formattedDate: option.formattedDate, formattedCost: option.formattedCost, formattedSellingTax: option.formattedSellingTax, serviceTypeID: this.serviceTypeID, serviceID: option.serviceID, optionID: option.optionID, priceCurrency: option.priceCurrency, date: option.date, isIncluded: this.isIncluded, valueType: option.valueType, onHoldQTY: option.onHoldQTY, isPercentage: this.isPercentage, onRequest:option.onRequest })).filter((detail, i, a) => a.findIndex(detail2 => +(detail.date ?? 0) === +(detail2.date ?? 0)) === i) as IReservationOptionDetail[];
  }

  public getTotalPrice(factor?: number): string {
    if (this.isPercentage) return this.sellingPrice + '%'

    //const currency = this.sellingPriceCurrency
    const currency = this.priceCurrency

    let priceNumber = this.availableSelectedOptionsDetail.reduce((acc, cur) => acc + (this.getFormattedPriceNumber(cur) ?? 0), 0)

    if (factor != undefined) priceNumber = priceNumber * factor

    return this.coreFormatService?.CurrencyAmount(priceNumber, currency) ?? ''
  }

  public getFormattedPriceNumber(option: ReservationServiceOption | IReservationOptionDetail): number {
    if (!option.formattedPrice) return 0;
    return ReservationService.getNumberFromString(option.formattedPrice)
  }

  public recalculateSelectedOptionsDetail() {
    this.availableSelectedOptionsDetail = this.getAvailableSelectedOptionsDetail()
  }

  public get minOnHoldQTY(): number {
    if (!this.availableSelectedOptionsDetail.length) return 0
    return Math.min(...(this.availableSelectedOptionsDetail.map(o => o.onHoldQTY).filter(o => o) as number[]))
  }

  public isDateAvailable(date: DateTime): boolean {
    if (!date?.isValid) return false
    return this.availableDates.some((d: DateTime) => +d === +date)
  }
}

export interface IReservationApiServiceOption {
  ServiceTypeID?: number;
  ServiceTypeName?: string;
  ServiceTypeDescription?: string;
  GuestConstraint?: boolean;
  ServiceID?: number;
  ServiceLongName?: string;
  ServiceShortName?: string;
  ServiceName?: string;
  ServiceDescription?: string;
  SupplierName?: string;
  OptionID?: number;
  OptionCod?: string;
  MinPax?: number;
  MaxPax?: number;
  NeedMessage?: string;
  CsFlag?: string;
  OptionName?: string;
  OptionShortName?: string;
  OptionDesc?: string;
  EstimatedCost?: number;
  Cost?: number;
  CostTypeID?: number;
  CostCurrency?: string;
  PriceSetUp?: string;
  SellingPrice?: number;
  SellingPriceCurrency?: string;
  SellingTax?: number;
  PriceCurrency?: string;
  Currency?: string;
  Margin?: number;
  Date?: string;
  ValueType?: 'Amount' | 'Percentage';
  StartDate?: string;
  EndDate?: string;
  Message?: string;
  PrePostFlag?: string;
  CommissionableSO?: string;
  OnHoldQTY?: number;
  GuestCod?: string;
  OnBoardUse?: boolean;
  IsIncluded?: string;
  SelfIncluded?: string;
  INCServiceTypeID?: number;
  OnRequest?: boolean;
}

export class ReservationServiceData extends BaseObject {
  serviceTypeID?: number;
  serviceTypeName?: string;
  serviceTypeDescription?: string;
  guestConstraint?: boolean;
  serviceID?: number;
  serviceLongName?: string;
  serviceShortName?: string;
  serviceDescription?: string;
  serviceDisplayNameUnique?:string;
  /* options?: ReservationServiceOption[];
  filteredOptions?: ReservationServiceOption[]; */
  inclusiveItems?: ReservationServiceOption[]
  filteredInclusiveItems?: ReservationServiceOption[]
  supplierName?: string;
  country?: string;
  serviceDisplayName?: string;

  constructor(
    data?: IReservationApiService,
    coreFormatService?: CoreFormatService
  ) {
    super(data);
    if (!!data) {
      this.updateData(data, coreFormatService);
    }
  }

  override updateData(
    data: IReservationApiService,
    coreFormatService?: CoreFormatService
  ): void {
    this.addMangledProperty(data);
    this.addBooleanProperty('guestConstraint', data, 'GuestConstraint');
    this.inclusiveItems =
      data.InclusiveItems?.map(
        (option) => new ReservationServiceOption(option, coreFormatService)
      ) ?? [];

    if (!this.inclusiveItems?.length) {
      this.filteredInclusiveItems = [];
    } else {
      let options = this.inclusiveItems.filter(
        (o, i, a) => a.findIndex((x) => x.optionID === o.optionID) === i
      );


      options.forEach((o) => {
        o.allAvailableOptions = (this.inclusiveItems
          ?.filter((x) => x.optionID === o.optionID)) ?? [];
      });
      this.filteredInclusiveItems = options;
    }

  }
}

export class ReservationOptionCost extends BaseObject {
  cost: number;
  currency: string;
  date: DateTime;
  dateCost: DateTime;
  optionCostPerDayID: number;
  optionID: number;
  optionName: string;
  serviceID: number;
  supplierID: number;

  constructor(data: IReservationApiOptionCost) {
    super();
    this.cost = 0;
    this.currency = '';
    this.date = DateTime.min();
    this.dateCost = DateTime.min();
    this.optionCostPerDayID = 0;
    this.optionID = 0;
    this.optionName = '';
    this.serviceID = 0;
    this.supplierID = 0;
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiOptionCost): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('date', data, 'Date');
    this.addDateTimeProperty('dateCost', data, 'DateCost');
  }
}
export class ReservationOptionPrice extends BaseObject {
  price: number;
  currency: string;
  date: DateTime;
  datePrice: DateTime;
  pricePerDateID: number;
  optionID: number;
  optionName: string;
  serviceID: number;
  priceTypeID: number
  margin: number;
  rOE: number;
  snglSuppCommPerc: number;
  snglSuppType: string;
  snglSuppValue: number;


  constructor(data: IReservationApiOptionPrice) {
    super();
    this.price = 0;
    this.currency = '';
    this.date = DateTime.min();
    this.datePrice = DateTime.min();
    this.pricePerDateID = 0;
    this.optionID = 0;
    this.optionName = '';
    this.serviceID = 0;
    this.priceTypeID = 0;
    this.margin = 0;
    this.rOE = 0;
    this.snglSuppCommPerc = 0;
    this.snglSuppType = '';
    this.snglSuppValue = 0;
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiOptionPrice): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('date', data, 'Date');
    this.addDateTimeProperty('datePrice', data, 'DatePrice');
  }

  get marginPercentage(): string {
    return this.margin != null ? `${this.margin}%` : '';
  }
}



export interface IReservationApiOptionCost {
  Cost?: number;
  Currency?: string;
  Date?: string;
  DateCost?: number;
  OptionCostPerDayID?: number;
  OptionID?: number;
  OptionName?: string;
  ServiceID?: number;
  SupplierID?: number;
}
export interface IReservationApiOptionPrice {
  Price?: number;
  Currency?: string;
  Date?: DateTime;
  DatePrice?: DateTime;
  PricePerDateID?: number;
  OptionID?: number;
  OptionName?: string;
  ServiceID?: number;
  PriceTypeID?: number;
  ROE?: number;
  SnglSuppCommPerc?: number;
  SnglSuppType?: string;
  SnglSuppValue?: number;
}

export interface IReservationApiService {
  ServiceTypeID?: number;
  ServiceTypeName?: string;
  ServiceTypeDescription?: string;
  GuestConstraint?: string;
  ServiceID?: number;
  ServiceLongName?: string;
  ServiceShortName?: string;
  ServiceDescription?: string;
  InclusiveItems?: IReservationApiServiceOption[];
  SupplierID?: number;
  SupplierName?: string;
  OptionName?: string;
  ServiceDisplayNameUnique?:string;
  Country?: string;
  ServiceDisplayName?: string;
}

export interface IReservationGetBkgServicesParams {
  BkgID?: number;
  SectionID?: number;
  ServiceTypeID?: number;
  SearchText?: string;
  SupplierName?: string;
  OptionName?: string;
  DateFrom?: DateTime;
  DateTo?: DateTime;
  CountryCod?: string;
  CityID?: number;
  CityIDPost?: number;
}

export interface IReservationGetBkgOptionsParams {
  BkgID?: number;
  SectionID?: number;
  ServiceID?: number;
  SearchText?: string;
  DateFrom?: DateTime;
  DateTo?: DateTime;
  SupplierName?: string;
  OptionName?: string;
}
export interface IReservationAddServiceOptionParams {
  BkgID?: number;
  VersionID?: number;
  PackageOptions?: IReservationAddServiceOptionPackageParams[];
  ServiceIncluded?: IReservationAddServiceOptionPackageParams[];
  LoyaltyRedemption?: IReservationAddServiceOptionLoyaltyRedemption[];
  Certificate?: IReservationAddServiceOptionCertificate[];
  GuestCod?: string;
  Alternative?: string;
  NewSuite?: IReservationApiParametersSuite[]
  BookCamp?: IReservationApiParametersBookCamp[]
  KeySuiteID?: number;
  RoomID?: number;
  SuiteNum?: string;
}

export interface IReservationAddServiceOptionLoyaltyRedemption {
  OptionCod?: string;
  IndividualID?: number;
  TierID?: number;
  Note?: string;
}

export interface IReservationAddServiceOptionCertificate {
  CertificateID: number;
  BkgID: number;
  GuestCod: string;
  Amount: number;
  CurrencyCod: string;
}

export interface IReservationEditServiceOptionParams {
  BkgID?: number;
  SectionID?: number;
  Items?: IReservationEditServiceOptionItem[];
}

export interface IReservationEditServiceOptionItem {
  ItemID?: number;
  ItineraryDate?: DateTime;
  SellingPrice?: string;
  Commissionable?: string;
  ForcedSupplierID?: number;
}

export interface IReservationDeleteOptionPackageParams {
  BkgID?: number;
  GuestCod?: string;
  VersionID?: number;
  PackageOptions?: IReservationEditServiceOptionItem[];
}

export interface IReservationDeleteServiceOptionItem {
  ServiceID?: number;
  OptionID?: number;
  ItineraryDate?: DateTime;
  GuestCod?: string;
}

export interface IReservationAddServiceOptionPackageParams {
  ServiceID?: number;
  OptionID?: number;
  VoyageID?: number
  PackageID?: number
  ItineraryDate?: DateTime;
  Price?: number;
  Markup?: number;
  SBCMessage?: string;
  Quantity?: number;
  GuestTo?: string
  Alternative?: string
}
export interface IReservationUpdateMessageParams {
  BkgID?: number;
  OptionID?: number;
  ItemID?: number;
  GuestCod?: string;
  SectionID?: number;
  Message?: string;
}


export interface IReservationApiGetServices {
  ServiceID?: number;
  ServiceLongName?: string;
  ServiceShortName?: string;
  ServiceDescription?: string;
  ServiceMaximumOccupancy?: number;
  ServiceTypeID?: number;
  SupplierID?: number;
  CompanyID?: number;
  SiteID?: number;
  SupplierName?: string;
  Country?: string;
  CityID?: number;
  CompanyID_OVERWRITE?: number;
}

export interface IReservationApiCloneServices {
  ServiceID: number
}

export interface IReservationApiGetOptions {
  ServiceID?: number;
  OptionID?: number;
  MinPax?: number;
  MaxPax?: number;
  OptionName?: string;
  OptionShortName?: string;
  OptionDesc?: string;
  Commissionable?: string;
  Refundable?: string;
  IsSBC?: string;
  IsIncluded?: string;
  IsActive?: string;
}

export interface IReservationApiGetOptionCost {
  ServiceID?: number;
  OptionID?: number;
  Year?: number;
}


export interface IReservationApiManageV2OptionCost {
  ServiceID?: number;
  OptionID?: number;
  Year?: number;
  CostSetup?: IReservationApiCostPriceSetup[]
}
export interface IReservationApiManageV2OptionPrice {
  ServiceID?: number;
  OptionID?: number;
  Year?: number;
  PriceTypesID?: string;
  PriceTypeID?: number;
  PriceSetup?: IReservationApiCostPriceSetup[];
  CurrencySetup?: IReservationCurrencySetup[];
  PriceSetupID?: number;
  CurrencyCod?: string;
}

export interface IReservationApiCostPriceSetup {
  PerDayID?: number;
  OptionID?: number;
  ServiceID?: number;
  DateStart?: string; // date per availability
  DateEnd?: string; // date per availability
  DateFrom?: DateTime; // date per cost
  DateTo?: DateTime; // date per cost
  Cost?: string;
  Currency?: string;
  PriceTypeID?: number;
  PriceSetUpID?: number;
  Margin?: string;
  ROE?: string;
  Price?: string;
  Status?: 'A' | 'D';
  SnglSuppCommPerc?: number;
  SnglSuppType?: string;
  SnglSuppValue?: number;
}

export interface IReservationCurrencySetup {
  Currency: string;
  Margin: number;
  ROE: number;
  FixedPrice: number;
}

export interface IReservationApiGetOptionPrice {
  ServiceID?: number;
  OptionID?: number;
  Year?: number;
  CurrencyCod?: string;
  PriceTypeID?: number;
}
export interface IReservationSuiteType {
  VoyageID: number;
  SuiteNumber?: number | undefined;
  SuiteCategoryID?: number;
  SuiteCategoryCod?: string | undefined;
  SuiteCapacityID?: number;
  IsGty: string;
}

export interface IReservationCheckComboAvailabilityParams {
  BkgID?: number;
  VersionID?: number;
  NewSuite: Array<IReservationSuiteType>
}

export class ReservationOptionAvailability extends BaseObject {
  availabilitybyOptionID: number;
  date: DateTime;
  propertyID: number;
  optionID: number;
  serviceID: number;
  initialQTY: number;
  onHoldQTY: number;
  initialAllotmentQTY: number;
  onHoldAllotmentQTY: number;
  priceTypeID: number;
  agencyID: number;

  constructor(data: IReservationApiOptionPrice) {
    super();
    this.availabilitybyOptionID = 0
    this.date = DateTime.min();
    this.propertyID = 0;
    this.optionID = 0;
    this.serviceID = 0;
    this.initialQTY = 0;
    this.onHoldQTY = 0;
    this.initialAllotmentQTY = 0;
    this.onHoldAllotmentQTY = 0;
    this.priceTypeID = 0;
    this.agencyID = 0;
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiOptionPrice): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('date', data, 'Date');
  }
}

export interface IReservationApiOptionAvailability {
  AvailabilitybyOptionID?: number;
  Date?: DateTime;
  PropertyID?: number;
  OptionID?: number;
  ServiceID?: number;
  InitialQTY?: number;
  OnHoldQTY?: number;
  InitialAllotmentQTY?: number;
  OnHoldAllotmentQTY?: number;
  PriceTypeID?: number;
  AgencyID?: number;
  Status?: 'A' | 'D';
}
export interface IReservationApiOptionAllocation {
  AllocationbyOptionID?: number;
  Date?: DateTime;
  PropertyID?: number;
  OptionID?: number;
  ServiceID?: number;
  InitialAllotmentQTY?: number;
  OnHoldAllotmentQTY?: number;
  Status?: string;
  PackageID?: number;
  PackageTravelDateID?: number
  Reason?: string;
}

export interface IReservationApiGetOptionAvailability {
  ServiceID?: number;
  OptionID?: number;
  Year?: number;
}
export interface IReservationApiGetOptionAllocation {
  ServiceID?: number;
  OptionID?: number;
  Year?: number;
}
export interface IReservationApiGetAllocationByOption {
  ServiceID: number;
  OptionID: number;
  Year: number;
  PackageID?: number;
  DateFrom?: DateTime;
  DateTo?: DateTime;
  OnlyPkg?: string;
}

export interface IReservationApiManageAllocationByOption {
  ServiceID?: number;
	OptionID?: number;
	PackageID?: number;
	AllocationbyOptionIDs?: string;
	AllocationQTY?: number;
	Status?: string;
}

export interface IReservationApiGetOptionAllocationDetails {
  ServiceID?: number;
  OptionID?: number;
  PackageID?: number;
  DateFrom?: DateTime;
  DateTo?: DateTime;
}

export interface IReservationApiManageOptionAvailability {
  Availabilities: IReservationApiOptionAvailability[],
}
export interface IReservationApiManageOptionAllocation {
  Allocations: IReservationApiOptionAllocation[],
}

export interface IResApiReplaceOptionsParams {
  Opt2Replace: IResApiReplaceOption[]
  ReplaceByDate: 'Y' | 'N';
}

export interface IResApiReplaceOption {
  BkgID: number;
  ServiceID_OLD: number;
  OptionID_OLD: number;
  ServiceID_NEW: number;
  OptionID_NEW: number;
  ManageAvail: boolean;
  ItineraryDate?: DateTime;
}

export interface IResApiReplaceAvailability {
  IsAvl: number;
  BkgID: number;
  ServiceID_OLD: number;
  OptionID_OLD: number;
}

export interface IResApiReplaceStatus {
  BkgID: number;
  ServiceID: number;
  OptionID: number;
  Status: 'OK' | 'KO'
  StatusMessage: string;
}

export interface IResApiGetReplaceOptionsHistoryParams {
  BkgID?: number;
  ServiceID_OLD?: number;
  OptionID_OLD?: number;
  ServiceID_NEW?: number;
  OptionID_NEW?: number;
  DateFrom?: DateTime;
  DateTo?: DateTime;
  Status?: 'OK' | 'KO';
}

export class ResReplaceHistory extends SafeBaseObject {
  iD: number = 0;
  [key: string]: any;

  constructor(data?: IResApiReplaceHistory) {
    super();
    if (data) this.updateData(data);
  }
  override updateData(data: IResApiReplaceHistory): void {
    this.addMangledProperty(data);
  }
}

export interface IResApiReplaceHistory {
  ID: number;
}


export interface IReservationGetAllocations{
ServiceID?: number;
OptionID?: number;
Date: DateTime;
InitialAllotmentQTY: number;
OnHoldAllotmentQTY: number;
ServiceName: string;
OptionName: string;
PackageNames: string;
}

export class ReservationGetAllocations extends SafeBaseObject {
  serviceID = 0;
  optionID = 0;
  date = DateTime.invalid('empty');
  initialAllotmentQTY = 0;
  onHoldAllotmentQTY = 0;
  serviceName = '';
  optionName = '';
  packageNames = ''

  constructor(data?: IReservationGetAllocations) {
    super();
    if (data) this.updateData(data);
  }
  override updateData(data: IReservationGetAllocations): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('date', data, 'Date');
    if(data.PackageNames) {
      this.packageNames = data.PackageNames.replace(/\|/g, '\n');
    }
  }
}

export interface IReservationOptionPriceAllocation {
  PackageID: number;
  PackageName: string;
  DateFrom: DateTime;
  DateTo: DateTime;
  InitialAllotmentQTY: number;
  OnHoldAllotmentQTY: number;
  AllocationbyOptionIDs: string;
  CanEdit: string;
  CanDelete: string;
}

export class ReservationOptionPriceAllocation extends SafeBaseObject {
  packageID = 0;
  packageName = '';
  dateFrom = DateTime.invalid('empty');
  dateTo = DateTime.invalid('empty');
  initialAllotmentQTY = 0;
  onHoldAllotmentQTY = 0;
  allocationbyOptionIDs = '';
  canEdit = false;
  canDelete = false;

  constructor(data?: IReservationOptionPriceAllocation) {
    super();
    if (data) this.updateData(data);
  }
  override updateData(data: IReservationOptionPriceAllocation): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('dateFrom', data, 'DateFrom');
    this.addDateTimeProperty('dateTo', data, 'DateTo');
    this.addBooleanProperty('canEdit', data, 'CanEdit');
  }
}

export interface IReservationOptionPriceAllocationDetail {
  PackageID: number;
  PackageName: string;
  Date: DateTime;
  InitialAllotmentQTY: number;
  OnHoldAllotmentQTY: number;
  AllocationbyOptionID: number;
  CanEdit: string;
  CanDelete: string;
}

export class ReservationOptionPriceAllocationDetail extends SafeBaseObject {
  packageID = 0;
  packageName = '';
  date = DateTime.invalid('empty');
  initialAllotmentQTY = 0;
  onHoldAllotmentQTY = 0;
  allocationbyOptionID = 0;
  checked?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;

  constructor(data?: IReservationOptionPriceAllocationDetail) {
    super();
    if (data) this.updateData(data);
  }
  override updateData(data: IReservationOptionPriceAllocationDetail): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('date', data, 'Date');
    this.addBooleanProperty('canEdit', data, 'CanEdit');
    this.addBooleanProperty('canDelete', data, 'CanDelete');
  }
}
